<template>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="auto">
          <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0" cols="auto">
          <v-btn icon>
              <v-icon v-if="link" color="secondary">mdi-magnify-expand</v-icon>
          </v-btn>
      </v-col>
      <v-col cols="12" v-if="items == null || items.length == 0">
        <v-row class="ma-0 pa-0"> 
          <v-col cols="12" style="height: 88px; overflow-y: hidden;">
            <v-skeleton-loader type="image,image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" style="height: 88px; overflow-y: hidden;">
            <v-skeleton-loader type="image,image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" style="height: 88px; overflow-y: hidden;">
            <v-skeleton-loader type="image,image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" >
        <v-list three-line>
          <template v-for="(item, index) in items">
            <v-subheader
              v-if="item.header"
              :key="index"
              v-text="item.header"
              class="list_header"
            ></v-subheader>

            <v-divider
              v-if="item.divider"
              :key="index"
              :inset="item.inset"
              class="my-1"
            ></v-divider>

            <v-list-item
              v-else
              :key="index"
            >
              <v-list-item-avatar tile class="ma-0 pa-0" min-height="88px" height="100%" width="75px">
                <!-- <v-img :src="item.avatar"></v-img> -->
                <div v-if="item.stat && ((index + 4) % 4 == 0)" class="date-box-light">
                  <div class="day">{{ item.stat }}</div>
                </div>
                <div v-else-if="item.stat && ((index + 4) % 4 != 0)" class="date-box-dark">
                  <div class="day">{{ item.stat }}</div>
                </div>
                <div v-else-if="item.date && ((index + 4) % 4 == 0)" class="date-box-light">
                  <div class="day">{{ get_day(item) }}</div>
                  <div class="month">{{ get_month(item) }}</div>
                </div>
                <div v-else-if="item.date && ((index + 4) % 4 != 0)" class="date-box-dark">
                  <div class="day">{{ get_day(item) }}</div>
                  <div class="month">{{ get_month(item) }}</div>
                </div>
              </v-list-item-avatar>

              <v-list-item-content class="ml-3">
                <v-list-item-title class="statistic_title" v-html="item.title" align="left"></v-list-item-title>
                <v-list-item-subtitle class="statistic_value" v-html="item.detail" align="left"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'StatList',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        mounted(){
            // if(this.statistic_title_name != null){
            //     this.items.push({header: this.statistic_title_name});
            // }else{
            //     this.items.push({header: 'Statistic'});
            // }
            if(this.value == null){
                for(let i = 0; i < 5; i ++){
                    this.items.push({
                        title: (i+1),
                        time: '2024-05-25',
                        detail: "Statistic List Item Description"
                    })
                    this.items.push({ divider: true, inset: true });
                }
            }
            else{
              for(let val of this.value){
                this.items.push(val);
                this.items.push({ divider: true, inset: true });
              }
            }
        },
        data: () => ({
            items: [
                
            ],
            months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        }),
        watch: {
          value: function(){
            if(this.value == null){
                for(let i = 0; i < 5; i ++){
                    this.items.push({
                        title: (i+1),
                        time: '2024-05-25',
                        detail: "Statistic List Item Description"
                    })
                    this.items.push({ divider: true, inset: true });
                }
            }
            else{
              for(let val of this.value){
                this.items.push(val);
                this.items.push({ divider: true, inset: true });
              }
            }
          }
        },
        methods: {
          get_day: function(item){
            let date = new Date(item.date)
            return date.getDate();
          },
          get_month: function(item){
            let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            let date = new Date(item.date)
            return months[date.getMonth()];
          }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;
    }
    .statistic_title{
        font-family: "Gothic Bold";
        font-size: 14pt;
    }
    .statistic_value{
        font-family: "Gothic";
        font-size: 12pt;
    }
    .list_header{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
    }
    .date-box-light {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #1230EF;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }
    .date-box-dark {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #020446;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }

    .day {
      font-size: 22px;
      font-weight: bold;
    }

    .month {
      font-size: 18px;
    }
</style>