<template>
  <v-container fluid class="px-8">
    <div v-if="showAlert">
      <v-alert
        class="v-alert"
        v-model="showAlert"
        border="left"
        close-text="Close Alert"
        dismissible
        :type="alertType"
        style="position: absolute"
      >
        {{ alertMsg }}
      </v-alert>
    </div>

    <v-row class="ma-0 pa-0" justify="space-around">
      <v-col cols="12">
        <v-row class="ma-0 pa-0" align="center">
          <v-col cols="auto" class="ma-0 pa-0">
            <span class="page-title">Ticketing</span>
            <a class="page-link" href="https://autotask.net/Mvc/Framework/Authentication.mvc/Authenticate" target="_blank">
              Autotask
              <v-icon color="primary">mdi-open-in-new</v-icon>
            </a>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-show="$vuetify.breakpoint.smAndUp" md="auto" class="ma-0 pa-0 px-3">
              <DateRange 
                  :start_date="start_date"
                  :end_date="end_date"
                  :min_start_date="min_start_date"
                  :max_end_date="max_end_date"
                  v-on:date_update="update_date_range"></DateRange>
          </v-col> 
          <!-- <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
            <v-icon v-on="on">mdi-calendar-range</v-icon>
          </v-col> -->
          <v-col v-show="$vuetify.breakpoint.lgAndUp" lg="auto" class="ma-0 pa-0 px-3">
              <span class="last_updated_title">Data Last Updated</span><br>
              <span class="last_updated_text">{{  data_info != null ? data_info : '-' }}</span>
          </v-col>
          <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-clock-outline</v-icon>
                  </template>
                  <TooltipDescription title="Data Last Updated." :desc_2="data_info != null ? data_info : '-'"></TooltipDescription>
              </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0 py-1">
          <v-chip @click="switch_tab('overview')" label class="data_breakdown_chip" :outlined="current_page != 'overview'" color="primary">Overview</v-chip>
          <v-chip @click="switch_tab('type_overview')" label class="data_breakdown_chip" :outlined="current_page != 'type_overview'" color="primary">Tickets By Type</v-chip>
          <v-chip @click="switch_tab('details')" label class="data_breakdown_chip" :outlined="current_page != 'details'" color="primary">Ticket Details</v-chip>
      </v-col>
      <v-row class="ma-0 pa-0" style="width: 100%" justify="space-around">
        <v-col cols="3" v-if="current_page == 'overview'" class="ma-0 py-3" >
          <MainPageStat
            statistic_title_name="Tickets Created"
            :value="created_tickets"
            :change="created_tickets_change"
            :odd_box="true"
          ></MainPageStat>
        </v-col>
        <v-col cols="3" v-if="current_page == 'overview'" class="ma-0 py-3" >
          <MainPageStat
            statistic_title_name="Open Tickets"
            :value="open_tickets"
            :change="open_tickets_change"
            :odd_box="true"
          ></MainPageStat>
        </v-col>
        <v-col cols="3" v-if="current_page == 'overview'" class="ma-0 py-3" >
          <MainPageStat
            statistic_title_name="Tickets Closed"
            :value="closed_tickets"
            :change="closed_tickets_change"
            :odd_box="true"
          ></MainPageStat>
        </v-col>
      </v-row>
      <v-col cols="2" v-if="current_page == 'overview'" class="ma-0 py-3" >
        <MainPageStat
          statistic_title_name="High/Critical Priority Tickets"
          :value="high_priority_open_tickets"
          :change="high_priority_open_tickets_change"
          :odd_box="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="2" v-if="current_page == 'overview'" class="ma-0 py-3" >
        <MainPageStat
          statistic_title_name="On Hold Tickets"
          :value="on_hold_tickets"
          :change="on_hold_tickets_change"
          :odd_box="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="2" v-if="current_page == 'overview'" class="ma-0 py-3" >
        <MainPageStat
          statistic_title_name="Waiting on Customer Tickets"
          :value="waiting_customer_tickets"
          :change="waiting_customer_tickets_change"
          :odd_box="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="2" v-if="current_page == 'overview'" class="ma-0 py-3" >
        <MainPageStat
          statistic_title_name="Avg. Resolution Time"
          :value="avg_resolution_time"
          :change="avg_resolution_time_change"
          :odd_box="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="2" v-if="current_page == 'overview'" class="ma-0 py-3" >
        <MainPageStat
          statistic_title_name="Avg. Response Time"
          :value="avg_response_time"
          :change="avg_response_time_change"
          :odd_box="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="2" v-if="current_page == 'overview'" class="ma-0 py-3" >
        <MainPageStat
          statistic_title_name="Peak Submission Time"
          :value="peak_submission_time"
          :odd_box="true"
        ></MainPageStat>
      </v-col>
      <v-col cols="12" v-if="charts_config && current_page == 'overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="6" class="py-1">
            <BaseChart
              :statistic_title_name="charts_config['charts']['TicketsByPriority']['chart_title']"
              :value="tickets_by_priority"
              :chart_type="charts_config['charts']['TicketsByPriority']['chart_type']"
              chart_id="TicketsByPriority"
            >
            </BaseChart>
          </v-col>
          <v-col cols="6" class="py-1">
            <BaseChart
              :statistic_title_name="charts_config['charts']['TicketsByStatus']['chart_title']"
              :value="tickets_by_status"
              :chart_type="charts_config['charts']['TicketsByStatus']['chart_type']"
              chart_id="TicketsByStatus"
            >
            </BaseChart>
          </v-col>
          <v-col cols="2" class="py-1">
            <BaseChart
              statistic_title_name="Top 5 Ticket Categories"
              :value="charts_config['data']['TopCategories']['stat_value']"
              chart_type="List"
              chart_id="TicketsByStatus"
            >
            </BaseChart>
          </v-col>
          <v-col cols="2" class="py-1">
            <BaseChart
              statistic_title_name="Top 5 Ticket Submitters"
              :value="charts_config['data']['TopSenders']['stat_value']"
              chart_type="List"
              chart_id="TicketsByStatus"
            >
            </BaseChart>
          </v-col>
          <v-col cols="8" class="py-1">
            <BaseChart
              statistic_title_name="Daily Ticketing Overview"
              :value="daily_overview"
              chart_type="TimeSeriesChart"
              chart_id="DailyOverview"
            >
            </BaseChart>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="6" v-if="charts_config && current_page == 'overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="py-1">
            <BaseChart
              statistic_title_name="Top 5 Ticket Categories"
              :value="charts_config['charts']['TicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['TicketsByPriority']['chart_type']"
              chart_id="TicketsByPriority"
            >
            </BaseChart>
          </v-col>
        </v-row>
      </v-col> -->
      <v-col cols="12"  v-else-if="current_page == 'overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="2">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="2">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="8">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else-if="charts_config && current_page == 'type_overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="py-1">
            <span class="section-title">Service Request Tickets</span>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['Service RequestTicketsByPriority']['chart_title']"
              :value="charts_config['charts']['Service RequestTicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['Service RequestTicketsByPriority']['chart_type']"
              chart_id="ServiceRequestTicketsByPriority"            
              >
            </BaseChart>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['Service RequestTicketsOverview']['chart_title']"
              :value="charts_config['charts']['Service RequestTicketsOverview']['chart_value']"
              :chart_type="charts_config['charts']['Service RequestTicketsOverview']['chart_type']"
              chart_id="ServiceRequestTicketsOverview"
            >
            </BaseChart>
          </v-col>
          <v-col cols="12" class="py-1 pt-6">
            <span class="section-title">Change Request Tickets</span>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['Change RequestTicketsByPriority']['chart_title']"
              :value="charts_config['charts']['Change RequestTicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['Change RequestTicketsByPriority']['chart_type']"
              chart_id="ChangeRequestTicketsByPriority"
            >
            </BaseChart>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['Change RequestTicketsOverview']['chart_title']"
              :value="charts_config['charts']['Change RequestTicketsOverview']['chart_value']"
              :chart_type="charts_config['charts']['Change RequestTicketsOverview']['chart_type']"
              chart_id="ChangeRequestTicketsOverview"
            >
            </BaseChart>
          </v-col>
          <v-col cols="12" class="py-1 pt-6">
            <span class="section-title">Incident Tickets</span>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['IncidentTicketsByPriority']['chart_title']"
              :value="charts_config['charts']['IncidentTicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['IncidentTicketsByPriority']['chart_type']"
              chart_id="IncidentTicketsByPriority" 
            >
            </BaseChart>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['IncidentTicketsOverview']['chart_title']"
              :value="charts_config['charts']['IncidentTicketsOverview']['chart_value']"
              :chart_type="charts_config['charts']['IncidentTicketsOverview']['chart_type']"
              chart_id="IncidentTicketsOverview" 
            >
            </BaseChart>
          </v-col>
          <v-col cols="12" class="py-1 pt-6">
            <span class="section-title">Alert Tickets</span>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['AlertTicketsByPriority']['chart_title']"
              :value="charts_config['charts']['AlertTicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['AlertTicketsByPriority']['chart_type']"
              chart_id="AlertTicketsbyPriority" 
            >
            </BaseChart>
          </v-col>
          <v-col cols="6" class="py-0">
            <BaseChart
              :statistic_title_name="charts_config['charts']['AlertTicketsOverview']['chart_title']"
              :value="charts_config['charts']['AlertTicketsOverview']['chart_value']"
              :chart_type="charts_config['charts']['AlertTicketsOverview']['chart_type']"
              chart_id="AlertTicketsOverview" 
            >
            </BaseChart>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else-if="current_page == 'type_overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="py-1">
            <span class="section-title">Service Request Tickets</span>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" class="py-1 pt-6">
            <span class="section-title">Change Request Tickets</span>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" class="py-1 pt-6">
            <span class="section-title">Incident Tickets</span>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" class="py-1 pt-6">
            <span class="section-title">Alert Tickets</span>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"  v-if="current_page == 'details'" class="ma-0 py-1" >
        <StatisticsTable
          statistic_title_name="Open Tickets"
          :value="open_tickets_lst"
        ></StatisticsTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainPageStat from "../StatisticComponents/MainPageStat";
import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
import DateRange from "../UtilityComponents/DateRange.vue";
import StatisticsTable from "../StatisticComponents/StatisticsTable.vue";
import BaseChart from "../StatisticComponents/Charts/BaseChart.vue";
import api_caller from "@/javascript/chart_config_retrieval.js";
import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";


export default {
  name: "TicketingPage",
  props: [
    'start_date',
    'end_date',
    'system_min_start_date',
    'system_max_end_date'
  ],
  data: () => ({
    alertMsg: null,
    alertType: null,
    showAlert: false,
    ticketing_raw_data: null,
    open_tickets_lst: [],
    old_tickets: [],

    created_tickets: null,
    created_tickets_change: null,
    open_tickets: null,
    open_tickets_change: null,
    closed_tickets: null,
    closed_tickets_change: null,
    high_priority_open_tickets: null,
    high_priority_open_tickets_change: null,
    on_hold_tickets: null,
    on_hold_tickets_change: null,
    waiting_customer_tickets: null,
    waiting_customer_tickets_change: null,
    avg_resolution_time: null,
    avg_resolution_time_change: null,
    avg_response_time: null,
    avg_response_time_change: null,
    peak_submission_time: null,

    daily_overview: null,
    tickets_by_priority: null,
    tickets_by_status: null,

    open_tickets_by_priority: [],
    charts_config: null,
    echart_option: null,

    data_info: null,

    current_page: 'overview',

    menu: false,
    
    date_range: [],
    min_start_date: '2024-01-01',
    max_end_date: '2024-12-31',
    // start_date: '2024-01-01',
    // end_date: '2024-01-01'
  }),
  components: {
    StatisticsTable,
    MainPageStat,
    BaseChart,
    TooltipDescription,
    DateRange
  },
  mounted() {
    this.date_range = [this.start_date, this.end_date];
    this.min_start_date = this.system_min_start_date;
    this.max_end_date = this.system_max_end_date;
    this.getChartConfig();
  },
  methods: {
    switch_tab: async function(new_tab){
      let cc = {...this.charts_config};
      this.charts_config = null;
      this.current_page = new_tab;
      setTimeout(() => this.charts_config = cc, 500);
    },
    update_date_range: function(date_range){
        this.date_range = date_range;
        this.$emit('system_date_update', {'start': date_range[0], 'end': date_range[1]});
        this.getChartConfig();
    },
    getChartConfig: async function () {
      this.charts_config = null;
      
      this.created_tickets = null;
      this.created_tickets_change = null;

      this.open_tickets = null;
      this.open_tickets_change = null;

      this.closed_tickets = null;
      this.closed_tickets_change = null;

      this.high_priority_open_tickets = null;
      this.high_priority_open_tickets_change = null;

      this.on_hold_tickets = null;
      this.on_hold_tickets_change = null;

      this.waiting_customer_tickets = null;
      this.waiting_customer_tickets_change = null;

      this.avg_resolution_time = null;
      this.avg_resolution_time_change = null;

      this.avg_response_time = null;
      this.avg_response_time_change = null;

      this.peak_submission_time = null;

      this.daily_overview = null;
      this.tickets_by_priority = null;
      this.tickets_by_status = null;

      this.open_tickets_lst = [];
      const token = await this.$auth.getTokenSilently();
      let charts_config = await api_caller
      .get_ticketing_charts_config(token, this.date_range[0], this.date_range[1])
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });

      if (charts_config) {
        this.charts_config = charts_config;
        this.open_tickets_lst = this.charts_config['data']['OpenTicketsLst']['content']
        
        this.created_tickets = this.charts_config['data']['CreatedTickets']['stat_value'];
        this.created_tickets_change = this.charts_config['data']['CreatedTickets']['stat_change'];

        this.open_tickets = this.charts_config['data']['OpenTickets']['stat_value'];
        this.open_tickets_change = this.charts_config['data']['OpenTickets']['stat_change'];

        this.closed_tickets = this.charts_config['data']['ClosedTickets']['stat_value'];
        this.closed_tickets_change = this.charts_config['data']['ClosedTickets']['stat_change'];

        this.high_priority_open_tickets = this.charts_config['data']['HighPriorityTickets']['stat_value'];
        this.high_priority_open_tickets_change = this.charts_config['data']['HighPriorityTickets']['stat_change'];
        
        this.on_hold_tickets = this.charts_config['data']['OnHoldTickets']['stat_value'];
        this.on_hold_tickets_change = this.charts_config['data']['OnHoldTickets']['stat_change'];

        this.waiting_customer_tickets = this.charts_config['data']['WaitingCustomerTickets']['stat_value'];
        this.waiting_customer_tickets_change = this.charts_config['data']['WaitingCustomerTickets']['stat_change'];

        this.avg_resolution_time = this.charts_config['data']['TicketsMTTR']['stat_value'];
        this.avg_resolution_time_change = this.charts_config['data']['TicketsMTTR']['stat_change'];

        this.avg_response_time = this.charts_config['data']['TicketsRSP']['stat_value'];
        this.avg_response_time_change = this.charts_config['data']['TicketsRSP']['stat_change'];

        this.peak_submission_time = this.charts_config['data']['PeakSubmissionTime']['stat_value'];

        this.daily_overview = this.charts_config['charts']['TicketsStatusDaily']['chart_value'];
        this.tickets_by_priority = this.charts_config['charts']['TicketsByPriority']['chart_value'];
        this.tickets_by_status = this.charts_config['charts']['TicketsByStatus']['chart_value'];
        
        this.data_info = charts_config['data_info']['data_last_updated'];


        // this.echartSetup()

      } else {
        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },
    echartSetup: function() {

      let rawChartData = this.charts_config['charts']['TicketsByPriority']['chart_value']

      let echartData = []
      for(let i = 1; i<rawChartData.length; i++) {
        echartData.push({name: rawChartData[i][0], value: rawChartData[i][1] })
      }

      this.echart_option = {}
      this.echart_option["pieChart"] = {
        title: {
          text: 'Pie Chart',
          left: 'center',
        },
        series: [
          {
            name: 'Pie Chart',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: echartData,
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      this.echart_option["funnelChart"] = {
        title: {
          text: 'Funnel Chart',
          left: 'center',
        },
        series: [
          {
            name: 'Funnel Chart',
            type: 'funnel',
            width: '80%',
            maxSize: '80%',
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    triggerAlert: function (type, message) {
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    }
  },
};
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title {
      font-family: "Gothic Bold" !important;
      font-size: 25pt !important;
    }
    .section-title {
      font-family: "Gothic Bold" !important;
      font-size: 20pt !important;
      text-decoration: underline;
    }
    .page-link {
      font-family: "Gothic" !important;
      font-size: 15pt !important;
      margin-left: 10px;
      color: #020446;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }

    .echart {
      height: 500px;
    }
</style>
