<template>
    <v-container fluid class="px-8">
        <div v-if="showAlert">
            <v-alert
                class="v-alert"
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
                style="position: absolute;"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-row class="ma-0 pa-0">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">User Settings</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row class="ma-0 pa-0 user-setting-content">
                    <v-col cols="12" class="ma-0 pa-0">
                        <div class="pl-3 user-settings-section-title">Data Points</div>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="3">
                                <v-select
                                class="user-settings-option"
                                light
                                style="width: 100%"
                                :items="data_point_options"
                                label="Data point 1"
                                :item-value="'value'"
                                :item-text="'label'"
                                v-model="preference_data_point_1"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                class="user-settings-option"
                                light
                                style="width: 100%"
                                :items="data_point_options"
                                label="Data point 2"
                                :item-value="'value'"
                                :item-text="'label'"
                                v-model="preference_data_point_2"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                class="user-settings-option"
                                light
                                style="width: 100%"
                                :items="data_point_options"
                                label="Data point 3"
                                :item-value="'value'"
                                :item-text="'label'"
                                v-model="preference_data_point_3"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                class="user-settings-option"
                                light
                                style="width: 100%"
                                :items="data_point_options"
                                label="Data point 4"
                                :item-value="'value'"
                                :item-text="'label'"
                                v-model="preference_data_point_4"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0">
                                <div class="pl-3 user-settings-section-title">Graphs</div>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="6">
                                        <v-select
                                        class="user-settings-option"
                                        light
                                        style="width: 100%"
                                        :items="graph_options"
                                        :item-value="'value'"
                                        :item-text="'label'"
                                        label="Graph 1"
                                        v-model="preference_graph_1"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                        class="user-settings-option"
                                        light
                                        style="width: 100%"
                                        :items="graph_options"
                                        :item-value="'value'"
                                        :item-text="'label'"
                                        label="Graph 2"
                                        v-model="preference_graph_2"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                        class="user-settings-option"
                                        light
                                        style="width: 100%"
                                        :items="graph_options"
                                        :item-value="'value'"
                                        :item-text="'label'"
                                        label="Graph 3"
                                        v-model="preference_graph_3"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                        class="user-settings-option"
                                        light
                                        style="width: 100%"
                                        :items="graph_options"
                                        :item-value="'value'"
                                        :item-text="'label'"
                                        label="Graph 4"
                                        v-model="preference_graph_4"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0">
                                <div class="pl-3 user-settings-section-title">Default Date Range</div>
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="4">
                                        <v-select
                                        light
                                        style="width: 100%"
                                        :items="date_range_options"
                                        :item-value="'value'"
                                        :item-text="'label'"
                                        v-model="selected_date_range"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        <v-row class="save-user-setting">
            <v-btn
                color="secondary"
                block
                @click="update_user_preferences"
            >
                Save User Settings
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, SUCCESS_USER_PREFERENCES_MESSAGE } from "@/constants/constants.js";
    import api_caller from "@/javascript/chart_config_retrieval";
    import { ref } from "vue";

    const GRAPH_PREFERENCE_OPTIONS = ref([
        { divider: true },
        { header: "Ticketing" },
        { divider: true },
        { label: "Tickets By Priority", value: "TicketsByPriority" },
        { label: "Tickets By Status", value: "TicketsByStatus" },
        { divider: true },
        { header: "Endpoint Protection" },
        { divider: true },
        { label: "Threats By Type", value: "ThreatsByType" },
        { label: "Threats By Detection Engine", value: "ThreatsByDetectionEngine" },
        { label: "Threats By Status", value: "ThreatsByStatus" },
        { label: "Threats By Analyst Verdict", value: "ThreatsByAnalystVerdict" },
        { divider: true },
        { header: "Device Monitoring" },
        { divider: true },
        { label: "Antivirus Status", value: "AntivirusStatus" },
        { label: "Offline Devices By Type", value: "OfflineDevicesByType" },
        // { divider: true },
        // { header: "Email Monitoring" },
        // { divider: true },
        // { label: "Email Activity In Past Week", value: "EmailActivityInPastWeek" },
        // { label: "Email Traffic", value: "EmailTraffic" },
        // { label: "Rejections By Type", value: "RejectionsByType" },
        ]);

    const DATA_POINT_PREFERENCE_OPTIONS = ref([
        { divider: true },
        { header: "Ticketing" },
        { divider: true },
        { label: "Created Tickets", value: "CreatedTickets" },
        { label: "Open Tickets", value: "OpenTickets" },
        { label: "Closed Tickets", value: "ClosedTickets" },
        { label: "High Priority Tickets", value: "HighPriorityTickets" },
        { label: "On Hold Tickets", value: "OnHoldTickets" },
        { label: "Waiting on Customer Tickets", value: "WaitingCustomerTickets" },
        { label: "Average Resolution Tickets", value: "TicketsMTTR" },
        { label: "Average Response Tickets", value: "TicketsRSP" },
        { label: "Peak Submission Time", value: "PeakSubmissionTime" },
        { divider: true },
        { header: "Endpoint Protection" },
        { divider: true },
        { label: "Total Threats", value: "TotalThreats" },
        { label: "Unresolved Threats", value: "UnresolvedThreats" },
        { label: 'Auto Resolved Threats', value: "AutoResolvedThreats"},
        { label: "Avg. Identification Time", value: "AvgIdentificationTime"},
        { label: "Avg. Resolution Time", value: "AvgResolutionTime"},
        { divider: true },
        { header: "Device Monitoring" },
        { divider: true },
        { label: "Offline Devices", value: "OfflineDevices" },
        { label: "Total Devices", value: "TotalDevices" },
        // { divider: true },
        // { header: "Email Monitoring" },
        // { divider: true },
        // { label: "Bounced Emails", value: "BouncedEmails" },
        // { label: "Rejected Emails", value: "RejectedEmails" },
        // { label: "Held Emails", value: "HeldEmails" },
        // { label: "Total Emails", value: "TotalEmails" },
        ]);
    
    const DATE_RANGE_OPTIONS = ref([
        { label: "Today", value: 0 },
        { label: "Yesterday", value: 1 },
        { label: "Last week", value: 7 },
        { label: "Last 2 weeks", value: 14 },
        { label: "Last month", value: 30 },
        ]);

    export default {
        name: 'UserSettings',
        props: [
        ],
        components: {
        },
        data: () => ({
            alertMsg: null,
            alertType: null,
            showAlert: false,
            data_point_options: DATA_POINT_PREFERENCE_OPTIONS,
            graph_options: GRAPH_PREFERENCE_OPTIONS,
            date_range_options: DATE_RANGE_OPTIONS,

            preference_data_point_1: null,
            preference_data_point_2: null,
            preference_data_point_3: null,
            preference_data_point_4: null,

            preference_graph_1: null,
            preference_graph_2: null,
            preference_graph_3: null,
            preference_graph_4: null,

            selected_date_range: 0
        }),
        mounted() {
            console.log("mounted hook")
            if (this.$auth.isAuthenticated) {
                this.get_user_preferences();
            }
        },
        methods: {
            get_user_preferences: async function () {
                let token = await this.$auth.getTokenSilently();
                let userPreferences = await api_caller
                    .get_homepage_user_preferences(token)
                    .then((resp) => {
                    if (!resp["success"]) {
                        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                    }
                    console.log('resp')
                    console.log(resp)
                    return resp["data"];
                    })
                    .catch((e) => {
                    console.log(e);
                    return false;
                    });
                if (userPreferences) {
                    console.log('userPreferences')
                    console.log(userPreferences)
                    let data_pref = userPreferences["data_points_stat"];
                    let graph_pref = userPreferences["graphs_stat"];

                    this.preference_data_point_1 = data_pref[0];
                    this.preference_data_point_2 = data_pref[1];
                    this.preference_data_point_3 = data_pref[2];
                    this.preference_data_point_4 = data_pref[3];

                    this.preference_graph_1 = graph_pref[0];
                    this.preference_graph_2 = graph_pref[1];
                    this.preference_graph_3 = graph_pref[2];
                    this.preference_graph_4 = graph_pref[3];
                    this.brands_pulled = true;
                }
            },
            update_user_preferences: async function () {
                let payload = {
                    config: {
                    numeric_statistic_a: this.preference_data_point_1,
                    numeric_statistic_b: this.preference_data_point_2,
                    numeric_statistic_c: this.preference_data_point_3,
                    numeric_statistic_d: this.preference_data_point_4,
                    graph_statistic_a: this.preference_graph_1,
                    graph_statistic_b: this.preference_graph_2,
                    graph_statistic_c: this.preference_graph_3,
                    graph_statistic_d: this.preference_graph_4,
                    },
                };

                let token = await this.$auth.getTokenSilently();
                let userPreferences = await api_caller
                    .update_homepage_user_preferences(token, payload)
                    .then((resp) => {
                    return resp["data"];
                    })
                    .catch((e) => {
                    console.log(e);
                    return false;
                    });
                if (userPreferences && userPreferences["rows_updated"] > 0) {
                    this.triggerAlert(ALERT_TYPE_SUCCESS, SUCCESS_USER_PREFERENCES_MESSAGE);
                    this.$router.replace('/home')
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            triggerAlert: function (type, message) {
                this.alertType = type ? type : "info";
                this.alertMsg = message;
                this.showAlert = true;
            }
        }
    }
</script>

<style>
    .page-title {
      font-family: "Gothic Bold" !important;
      font-size: 25pt !important;
    }
    .user-settings-section-title {
        font-family: "Gothic Bold" !important;
        font-weight: 400 !important;
        font-size: 1.5em !important;
        margin-top: 10px;
    }
    .user-settings-option {
        font-family: "Gothic" !important;
        font-weight: 400 !important;
        font-size: 0.9em !important;
        width: 80%;
    }
    .user-setting-content {
        height: 80vh;
    }
    .save-user-setting {
        width: 60%;
        margin: auto;
    }
</style>