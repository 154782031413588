<template>
    <v-container style="height: 100%; width: 100%;">
        <v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%; width: 100%;">
            <v-col cols="auto">
                <a class="page-link" href="https://auth.cynomi.com/u/login" target="_blank">
                    vCISO Dashboard
                    <v-icon color="primary" large>mdi-open-in-new</v-icon>
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'vCISOPage',
        props: [
        ],
        data: () => ({
        }),
        components: {
        },
        mounted(){
        }
    }
</script>

<style scoped>
    .page-title{
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .page-link{
        font-family: "Gothic Bold" !important;
        font-size: 30pt !important;
        margin-left: 10px;
        color: #020446;
    }
</style>