<template>
    <v-container fluid class="px-8">
        <div v-if="showAlert">
            <v-alert
                class="v-alert"
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
                style="position: absolute"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-row class="ma-0 pa-0">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">Pen Testing</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-show="$vuetify.breakpoint.mdAndUp" md="auto" class="ma-0 pa-0 px-3">
                        <DateRange 
                            :start_date="start_date"
                            :end_date="end_date"
                            :min_start_date="min_start_date"
                            :max_end_date="max_end_date"
                            v-on:date_update="update_date_range"></DateRange>
                    </v-col> 
                    <v-col v-show="$vuetify.breakpoint.lgAndUp" lg="auto" class="ma-0 pa-0 px-3">
                        <span class="last_updated_title">Data Last Updated</span><br>
                        <span class="last_updated_text">{{  data_info != null ? data_info : '-' }}</span>
                    </v-col>
                    <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-clock-outline</v-icon>
                            </template>
                            <TooltipDescription title="Data Last Updated." :desc_2="data_info != null ? data_info : '-'"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <StatisticsTable
                    statistic_title_name="All Risks"
                    :value="show_all_risks ? all_risks_data : table_data"
                    v-on:deleteRow="delete_penn_data"
                    v-on:editRow="edit_penn_data"
                ></StatisticsTable>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import api_caller from "@/javascript/chart_config_retrieval.js";
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";
    import StatisticsTable from "../StatisticComponents/StatisticsTable.vue";
    import DateRange from "../UtilityComponents/DateRange.vue";
    import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';

    export default {
        name: 'PennTesting',
        props: [
            'start_date',
            'end_date',
            'system_min_start_date',
            'system_max_end_date'
        ],
        data: () => ({
            alertMsg: null,
            alertType: null,
            showAlert: false,
            email_data_raw: null,
            show_all_risks: true,
            recent_alerts: [],
            all_risks_data: [],
            critical_data: [],
            high_data: [],
            medium_data: [],
            low_data: [],
            information_data: [],
            table_data: [],
            row_data: [4, 'Sia', 'altered description', 'C', 'Open', '127.0.0.1', 'Do not do anything.'],

            current_page: 'overview',

            email_activity_past_week: null,
            rejections_by_type: null,
            email_traffic: null,

            held_emails: null,
            bounced_emails: null,
            rejected_emails: null,
            charts_config: null,

            date_range: [],
            min_start_date: '2024-01-01',
            max_end_date: '2025-01-15',
        }),
        components: {
            StatisticsTable,
            TooltipDescription,
            DateRange
        },
        mounted(){
            this.min_start_date = this.system_min_start_date;
            this.max_end_date = this.system_max_end_date;
            this.date_range = [this.start_date, this.end_date];
            this.getChartConfig();
        },
        methods: {
            update_date_range: function(date_range){
                this.date_range = date_range;
                this.$emit('system_date_update', {'start': date_range[0], 'end': date_range[1]});
                this.getChartConfig();
            },
            updateGetChartConfig: async function () {
                const token = await this.$auth.getTokenSilently();
                let charts_config = await api_caller
                    .push_penn_data(token, 4, this.row_data, this.table_data['data'])
                    // .then((resp) => {
                    // if (resp["success"]) {
                    //     return resp["data"];
                    // } else {
                    //     return false;
                    // }
                    // })
                    .catch((e) => {
                    console.log(e);
                    return false;
                    });

                if (charts_config) {
                    this.charts_config = charts_config
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            getChartConfig: async function () {
                const token = await this.$auth.getTokenSilently();
                let charts_config = await api_caller
                    .get_penn_charts_config(token, this.date_range[0], this.date_range[1])
                    .then((resp) => {
                    if (resp["success"]) {
                        return resp["data"];
                    } else {
                        return false;
                    }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });

                if (charts_config) {
                    this.charts_config = charts_config
                    this.all_risks_data = this.charts_config['data']['AllRisks']['content'];

                    // added actions column
                    this.all_risks_data['header'].push({ text: "Actions", value: "actions", sortable: false });  
                    this.critical_data = this.charts_config['data']['CriticalRisks']['content'];
                    this.high_data = this.charts_config['data']['HighRisks']['content'];
                    this.medium_data = this.charts_config['data']['MediumRisks']['content'];
                    this.low_data = this.charts_config['data']['LowRisks']['content'];
                    this.information_data = this.charts_config['data']['InformationRisks']['content'];
                    this.table_data = this.all_risks_data
                    // console.log('data info', this.charts_config['data_info'])
                    this.data_info = this.charts_config['data_info']['data_last_updated'];
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            triggerAlert: function (type, message) {
                this.alertType = type ? type : "info";
                this.alertMsg = message;
                this.showAlert = true;
            },
            show_critical_data: function() {
                this.table_data = this.critical_data
            },
            on_click_outside () {
                this.show_all_risks = true
            },
            delete_penn_data: async function (row_id) {
                let payload = {
                    row_id: row_id
                };

                // remove the penn data row from database
                let token = await this.$auth.getTokenSilently();
                let result = await api_caller
                    .delete_penn_data(token, payload)
                    .then((resp) => {
                    if (resp["success"]) {
                        return resp["data"];
                    }
                    return false;
                    })
                    .catch((e) => {
                    console.log("error", e);
                    return false;
                    });
                if (result && result["rowDeleted"]) {
                    this.getChartConfig();
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            edit_penn_data: async function(payload) {

                let api_payload = {
                    row_id: payload['id'],
                    username: payload['username'],
                    description: payload['description'],
                    severity: payload['Severity'],
                    status: payload['Status'],
                    address_or_ip: payload['address_or_ip'],
                    recommendations: payload['recommendations']
                };

                // update the penn data row from database
                let token = await this.$auth.getTokenSilently();
                let result = await api_caller
                    .edit_penn_data(token, api_payload)
                    .then((resp) => {
                    if (resp["success"]) {
                        return resp["data"];
                    }
                    return false;
                    })
                    .catch((e) => {
                    console.log("error", e);
                    return false;
                    });

                if (result && result["rowEdited"]) {
                    this.getChartConfig();
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            }
        }
    }
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title{
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .page-link{
        font-family: "Gothic" !important;
        font-size: 15pt !important;
        margin-left: 10px;
        color: #66B85A;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }
</style>