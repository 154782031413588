<template>
    <v-row class="ma-0 pa-0">
        <!-- <span>
          {{ filterable_columns }}
        </span>
      <v-col class="ma-0 pa-0" cols="auto">
          <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0" cols="auto">
          <v-btn icon>
              <v-icon v-if="link" color="secondary">mdi-magnify-expand</v-icon>
          </v-btn>
      </v-col> -->
      <v-col cols="12" v-if="filterable_category != null">
        <v-row class="ma-0 pa-0" justify="space-between" align="center">
          <v-col cols="12" class="ma-0 pa-0 pl-12">
            <span class="chart_title">{{ filterable_category }}</span>
          </v-col>
          <v-col cols="1" class="pt-0" align="left">
            <v-icon @click="flip_category_left" large >mdi-chevron-left</v-icon>
          </v-col>
          <v-col cols="10" class="ma-0 pa-0" style="height: 100px;">
            <v-row class="ma-0 pa-0"  style="overflow-x: scroll; display: flex; flex-wrap: nowrap;" justify="space-around">
              <v-col cols="auto" class="ma-0 pa-0" v-for="(idx, stat) in highlight_map[filterable_category]" :key="stat">
                <statistics-table-stat :statistic_title_name="get_stat_header(filterable_category, stat)" :value="highlight_map[filterable_category][stat]" v-on:filter_value="filter_table" v-on:unfilter_value="unfilter_table" :filter="Object.keys(data_filters).includes(filterable_category) && data_filters[filterable_category].includes(stat)"></statistics-table-stat>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="auto" v-for="(idx, stat) in highlight_map[filterable_category]" :key="idx">
            <statistics-table-stat :statistic_title_name="stat" :value="highlight_map[filterable_category][stat]" v-on:filter_value="filter_table" v-on:unfilter_value="unfilter_table"></statistics-table-stat>
          </v-col> -->
          <v-col cols="1" class="pt-0" align="right">
            <v-icon @click="flip_category_right" large>mdi-chevron-right</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" >
        <v-data-table :headers="header" :items="items" align="left" height="55vh" fixed-header>
          <template v-slot:top>
            <v-dialog
                v-model="dialogEdit"
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="dialogTitle">Edit Data</span>
                  </v-card-title>
      
                  <v-card-text>
                    <v-container>
                      <v-row v-for="item in header" :key="item['value']">
                        <v-col cols="12">
                          <v-select v-if="item['value']=='Severity'"
                            v-model="selected_severity"
                            :items="severity_items"
                            item-text="severity_text"
                            item-vaue="severity_value"
                            :label="item['text']"
                          >
                          </v-select>
                          <v-select v-else-if="item['value']=='Status'"
                            v-model="selected_status"
                            :items="status_items"
                            item-text="status_text"
                            item-vaue="status_value"
                            :label="item['text']"
                          >
                          </v-select>
                          <v-text-field v-else-if="item['value'] != 'id' && item['value'] != 'actions'"
                            class="actionText"
                            v-model="tempEditData[item['value']]"
                            :label="item['text']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
      
                  <v-card-actions>
                    <v-row class="ma-0 pa-0">
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn class="actionText" outlined color="black" @click="dialogEdit = false;">Cancel</v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn class="actionText" outlined color="black" @click="editItemConfirm()">Save Changes</v-btn>
                      </v-col>
                    </v-row>                
                  </v-card-actions>
                </v-card>
              </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="550px">
                <v-card>
                  <v-card-title class="dialogTitle">Are you sure you want to delete this row?</v-card-title>
                  <v-card-actions>
                    <v-row class="ma-0 pa-0">
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn class="actionText" outlined color="black" @click="dialogDelete = false;">Cancel</v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn class="actionText" outlined color="black" @click="deleteConfirm">Delete</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
              </v-card>
              </v-dialog> 
        </template>

              <template v-for="(h,index) in header" v-slot:[`header.${h.value}`]>
                <span class="headerContent" :key="index">{{ h.text }}</span>
              </template>
              <template v-for="col in header" v-slot:[`item.${col.value}`]="{ item }">
                <div v-if="col.value == 'actions'"
                  :key="col.value"
                  class="actionCell"
                >
                  <div class="actionCellContent">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon elevation="0" v-on="on" @click="{{ editOnClick(item) }}">
                                <v-icon small color="black">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <TooltipDescription title="Edit Row" desc_2=""></TooltipDescription>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon elevation="0" v-on="on" @click="deleteOnClick(item)">
                                <v-icon small color="black">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <TooltipDescription title="Delete Row" desc_2=""></TooltipDescription>
                    </v-tooltip>
                  </div>
                </div>
                <span v-if='col.value=="Severity" && editable' class="tableContent" :key="col.value">
                  {{ severity_items.find((element) => element['severity_value'] == item[col.value])['severity_text'] }}
                </span>
                <span v-else-if='col.value=="Status" && editable' class="tableContent" :key="col.value">
                  {{ status_items.find((element) => element['status_value'] == item[col.value])['status_text'] }}
                </span>
                <div v-else :key="col.value">
                  <span v-if="item[col.value] && String(item[col.value]).length < 100" class="tableContent">{{ item[col.value] }}</span>
                  <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on }">
                              <span class="tableContent" v-on="on">{{ item[col.value] }}</span>
                          </template>
                          <TooltipDescription :title="item[col.value]" desc_2=""></TooltipDescription>
                  </v-tooltip>
                </div>
              </template>

              <!-- format all plain numeric columns -->
              <!-- <template v-for="numCol in headers" v-slot:[`item.${numCol.value}`]="{ item }">
                <div v-if="numericColumns.includes(numCol.value) || currencyColumns.includes(numCol.value) || percentageColumns.includes(numCol.value)" :key="numCol.value" :style="{backgroundColor: update_color(item)}" class="numericCellFlighting ma-0 pa-1 px-2">
                  <span v-if="numericColumns.includes(numCol.value)">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{ minimumFractionDigits: 0, maximumFractionDigits: 0}) : "n/a" }}
                  </span>
                  <span v-else-if="currencyColumns.includes(numCol.value)">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "currency", currency: "CAD", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else-if="percentageColumns.includes(numCol.value) && item[numCol.value] > 0" style="color:green">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else-if="percentageColumns.includes(numCol.value) && item[numCol.value] < 0" style="color:red">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else>
                    N/A
                  </span>
                </div>
                <div v-else :key="numCol.value" :style="{backgroundColor: update_color(item)}" class="stringCellFlighting ma-0 pa-1 px-2">
                  <span>
                    {{level_val(item, numCol.value)}}
                  </span>
                </div>
              </template> -->
        </v-data-table>
      </v-col>
    </v-row>
</template>

<script>
    import StatisticsTableStat from './StatisticsTableStat.vue';
    import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
    import { PENN_TEST_SEVERITY_OPTIONS, PENN_TEST_STATUS_OPTIONS } from '@/constants/constants';

    export default {
        name: 'StatisticsTable',
        props: [
            'statistic_title_name',
            'value',
            'link',
            'editable'
        ],
        components: {
          StatisticsTableStat,
          TooltipDescription
        },
        mounted(){
          if(this.value) {
            this.header = this.value['header'];
            this.items_raw = this.value['data'];
            this.items = this.value['data'];
            this.filterable_columns = this.value['filterable_columns'];
            this.pull_highlight_statistics();
          }
        },
        data: () => ({
            dialogEdit: false,
            dialogDelete: false,
            selected_severity: null,
            selected_status: null,
            status_items: PENN_TEST_STATUS_OPTIONS,
            severity_items: PENN_TEST_SEVERITY_OPTIONS,
            tempEditData: {},
            header: [],
            items: [],
            filterable_category: '',
            highlight_map: {},
            filterable_columns: [],
            data_filters: {}
        }),
        computed: {

        },
        watch: {
          value: function(){
            if(this.value.length > 0 || Object.keys(this.value).length > 0){
              this.header = this.value['header'];
              this.items_raw = this.value['data'];
              this.items = this.value['data'];
              this.filterable_columns = this.value['filterable_columns'];
              this.pull_highlight_statistics();
            }else{
              this.header = [];
              this.items_raw = [];
              this.items = [];
              this.filterable_columns = ['Loading...'];
              this.pull_highlight_statistics();
            }
          }
        },
        methods: {
          flip_category_left(){
            let cat_idx = this.filterable_columns.indexOf(this.filterable_category);
            cat_idx -= 1;
            if(cat_idx < 0){
              cat_idx = this.filterable_columns.length - 1;
            }
            this.filterable_category = this.filterable_columns[cat_idx];
          },
          flip_category_right(){
            let cat_idx = this.filterable_columns.indexOf(this.filterable_category);
            cat_idx += 1;
            if(cat_idx == this.filterable_columns.length){
              cat_idx = 0;
            }
            this.filterable_category = this.filterable_columns[cat_idx];
          },
          pull_highlight_statistics(){
            let highlight_map = {};

            for(let cat of this.filterable_columns){
              highlight_map[cat] = {};
              for(let row of this.items){

                if(Object.keys(row).includes(cat)){
                  if(Object.keys(highlight_map[cat]).includes(row[cat].toString())){
                    highlight_map[cat][row[cat]] += 1;
                  }
                  else{
                    highlight_map[cat][row[cat]] = 1;
                  }
                }
              }
            }
            this.highlight_map = highlight_map;
            this.filterable_category = this.filterable_columns[0];
          },
          unfilter_table(statistic_title){
            let new_filters = {};
            for(let filter_cat of Object.keys(this.data_filters)){
              new_filters[filter_cat] = [];
              for(let filter of this.data_filters[filter_cat]){
                if(filter == statistic_title){
                  continue;
                }else{
                  new_filters[filter_cat].push(filter);
                }
              }
            }
            this.data_filters = new_filters;
            this.filter_table(null);
          },
          filter_table(statistic_title){
            let new_data_rows = [];
            if(statistic_title != null){
              if(Object.keys(this.data_filters).includes(this.filterable_category)){
                // this.data_filters[this.filterable_category].push({'column': this.filterable_category, 'val': statistic_title})
                this.data_filters[this.filterable_category].push(statistic_title);
              }
              else{
                // this.data_filters[this.filterable_category] = [{'column': this.filterable_category, 'val': statistic_title}];
                this.data_filters[this.filterable_category] = [statistic_title];
              }
              // this.data_filters.push({'column': this.filterable_category, 'val': statistic_title})
            }
            let filtered_items = this.items_raw;
            for(let filter_cat of Object.keys(this.data_filters)){
              if(this.data_filters[filter_cat].length > 0){
                for(let row of filtered_items){
                  for(let filter of this.data_filters[filter_cat]){
                    if(row[filter_cat] == filter){
                      new_data_rows.push(row);
                    }
                  }
                }
                filtered_items = new_data_rows;
                new_data_rows = [];
                // this.items = new_data_rows;
              }
              // else{
              //   this.items = this.items_raw;
              // }
            }
            this.items = filtered_items;
          },
          get_stat_header: function (category, stat) {
            if(category == 'Severity' && this.editable) {
              return this.severity_items.find((element) => element['severity_value'] == stat)["severity_text"];
            } else if(category == 'Status' && this.editable) {
              return this.status_items.find((element) => element['status_value'] == stat)["status_text"];
            } else {
              return stat;
            }
          },
          editOnClick: function(item) {
            this.tempEditData = {...item}
            this.selected_severity = item['Severity'];
            this.selected_status = this.status_items.find((element) => element['status_value'] == item['Status'])["status_text"];
            this.selected_severity = this.severity_items.find((element) => element['severity_value'] == item['Severity'])["severity_text"];
            this.dialogEdit=true;
          },
          editItemConfirm: function() {
            this.tempEditData['Severity'] = this.severity_items.find((element) => element['severity_text'] == this.selected_severity)["severity_value"];
            this.tempEditData['Status'] = this.status_items.find((element) => element['status_text'] == this.selected_status)["status_value"];
            this.$emit('editRow', this.tempEditData);
            this.dialogEdit = false;
          },
          deleteOnClick: function(item) {
            this.rowToDelete = item['id']
            this.dialogDelete=true;
          },
          deleteConfirm:function() {
            this.$emit('deleteRow', this.rowToDelete);
            this.dialogDelete = false
          },
        }
    }
</script>

<style>
    .headerContent {
      font-family: "Gothic Bold" !important;
      font-size: 12pt;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      min-width: 80px;
      text-align: center;
      color: #FFFFFF;
    }
    .v-data-table__wrapper > table > thead > tr > th {
      background-color: #020446 !important;
    }
    .tableContent {
      font-family: "Gothic" !important;
      font-size: 10pt;
      display: inline-block;
      min-width: 10px;
      max-width: 250px;
      color: #000000;

      /* limit each table cell to show up to 3 lines */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 15pt !important;
        line-height: 15pt;
    }
    .statistic_title{
        font-family: "Gothic Bold";
        font-size: 14pt;
    }
    .statistic_value{
        font-family: "Gothic";
        font-size: 12pt;
    }
    .list_header{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
    }
    .date-box-light {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #85D97C;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }
    .date-box-dark {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #4C8D44;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }

    .day {
      font-size: 22px;
      font-weight: bold;
    }

    .month {
      font-size: 18px;
    }

    .actionCell {
      display: flex;
      height: 100%;
      max-width: 100px;
      align-items: center;
      justify-content: center;
      
      .actionCellContent {
        margin: auto 0;
        cursor: pointer;
      }
    }

    .dialogTitle {
      font-family: "Gothic Bold";
      font-weight: 600 !important;
      font-size: 1.2em !important;
    }

    .actionText {
      font-family: "Gothic";
      font-weight: 400 !important;
      font-size: 0.9em !important;
    }
</style>