<template>
  <v-app>
    <v-btn color="secondary" v-if="$auth.isAuthenticated && !$route.meta.hideNavbar && nav_bar_mini" class="docma-chevron-open-close-short" icon @click="toggle_nav_bar">
      <v-icon large color="primary">mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn color="secondary" v-if="$auth.isAuthenticated && !$route.meta.hideNavbar && !nav_bar_mini" class="docma-chevron-open-close-full" icon @click="nav_bar_mini=true">
      <v-icon large color="primary">mdi-chevron-left</v-icon>
    </v-btn>


    <v-dialog v-model="showTestDataDialog" max-width="400">
      <v-card>
        <v-card-title class="callout-headline">Welcome!</v-card-title>
        <v-card-text class="callout-text">
          Welcome to the QuickProtect Cyber Security Platform. <br />
          Please be aware that this tool is still in development and currently using test data.<br />
          Please reach out to the Docma team regarding any errors you may encounter<br />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showTestDataDialog = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-btn color="secondary" v-if="nav_bar_mini=true" class="docma-chevron-open-close-short" icon @click="toggle_nav_bar">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn color="secondary" v-if="nav_bar_mini=false" class="docma-chevron-open-close-full" icon @click="nav_bar_mini=true">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn> -->
    <!-- <v-btn color="secondary"
      v-else
      class="docma-text-navigation-logout docma-chevron-open-close-full"
      icon
      @click="nav_bar_mini=true">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn> -->
    <v-navigation-drawer permanent absolute style="height: 100vh;" :mini-variant="true" mini-variant-width="64px" v-if="$auth.isAuthenticated && !$route.meta.hideNavbar && nav_bar_mini">
      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <v-img
            :src="require('./assets/QuickView_Icon.svg')"
            contain
            to="/#/"
          />
        </v-col>
        <v-divider class="ma-0 pa-0 mx-5" color="secondary"></v-divider>
        <v-list-item one-line class="ma-0 px-3">
          <v-list-item-avatar v-if="$auth.isAuthenticated && $auth.user.picture">
            <img :src="$auth.user.picture" :alt="$auth.user.name" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else class="ma-0 pa-0 ml-1" color="green">
            <span class="white--text text-h5">{{ getUserInitial() }}</span>
          </v-list-item-avatar>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-5" color="secondary"></v-divider>
        <v-list-item one-line class="ma-0 px-3">
          <v-list-item-content>
                <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                      <v-icon dark color="secondary">mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Switch Organization
                  </span>
                </v-tooltip>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-5" color="secondary"></v-divider>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/home">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-home-outline</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Home
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/ticketing">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-ticket-outline</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Ticketing
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/device_monitoring">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-devices</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Device Monitoring
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/email_monitoring" disabled>
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-email-outline</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Email Monitoring
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/endpoint_protection">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-connection</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Endpoint Protection
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/penn_testing">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-shield-bug-outline</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Pen Testing
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/vciso">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-monitor-dashboard</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                VCISO
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/questionnaire">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-list-status</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Questionnaire
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" icon left to="/export">
            <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on">
                  <v-icon color="secondary">mdi-application-export</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Export
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
      <template v-slot:append>
        <v-row class="ma-0 pa-0">
          <!-- <v-col cols="12">
            <v-btn color="secondary"
              class="docma-text-navigation-logout"
              icon
              @click="nav_bar_mini=false">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="12">
            <v-btn color="secondary"
              class="docma-text-navigation-logout"
              icon
              @click="logout">
              <v-tooltip right v-if="Object.keys(user_orgs).length > 1">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="toolButton" v-on="on">
                    <v-icon color="secondary">mdi-logout</v-icon>
                  </v-btn>
                </template>
                <span class="tooltip_description" style="display: inline-block">
                  Logout
                </span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer permanent absolute style="height: 100vh; " v-if="$auth.isAuthenticated && !$route.meta.hideNavbar && !nav_bar_mini">
      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <v-img contain :src="require('./assets/QuickView_FullLogo.svg')" to="/#/" />
        </v-col>
        <v-divider class="ma-0 pa-0 mx-6" color="secondary"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar v-if="$auth.isAuthenticated && $auth.user.picture">
            <img :src="$auth.user.picture" :alt="$auth.user.name" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else class="ma-0 pa-0 ml-1" color="green">
            <span class="white--text text-h5">{{ getUserInitial() }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-drawer-text">
              <div class="organization-title">
                <div class="organization-title-content">
                  {{
                    $auth.user.name
                  }}
                </div>
                <v-tooltip left v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" to="/user_settings">
                      <v-icon dark color="secondary">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Edit user settings
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6" color="secondary"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar
            ><v-icon color="black">mdi-account-group-outline</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-drawer-text">
              <div class="organization-title">
                <div class="organization-title-content">
                  {{
                    user_orgs && user_orgs[$auth.user.org_id]
                      ? user_orgs[$auth.user.org_id]["display_name"]
                      : $auth.user.org_id
                  }}
                </div>
                <v-tooltip left v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                      <v-icon dark color="secondary">mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Switch Organization
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6" color="secondary"></v-divider>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/home">
            <v-icon color="secondary" class="pr-3">mdi-home-outline</v-icon>
            Home
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/ticketing">
            <v-icon color="secondary" class="pr-3">mdi-ticket-outline</v-icon>
            Ticketing
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn
            class="ma-0 pa-0 docma-text-navigation"
            text
            left
            to="/device_monitoring"
          >
            <v-icon color="secondary" class="pr-3">mdi-devices</v-icon>
            Device Monitoring
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/email_monitoring"  disabled>
            <v-icon color="secondary" class="pr-3">mdi-email-outline</v-icon>
            Email Monitoring
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn
            class="ma-0 pa-0 docma-text-navigation"
            text
            left
            to="/endpoint_protection"
          >
            <v-icon color="secondary" class="pr-3">mdi-connection</v-icon>
            Endpoint Protection
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/penn_testing">
            <v-icon color="secondary" class="pr-3">mdi-shield-bug-outline</v-icon>
            Pen Testing
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/vciso">
            <v-icon color="secondary" class="pr-3">mdi-monitor-dashboard</v-icon>
            VCiso
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/questionnaire">
            <v-icon color="secondary" class="pr-3">mdi-list-status</v-icon>
            Questionnaire
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn class="ma-0 pa-0 docma-text-navigation" text left to="/export">
            <v-icon color="secondary" class="pr-3">mdi-application-export</v-icon>
            Export Builder
          </v-btn>
        </v-col>
      </v-row>
      <template v-slot:append>
        <v-row class="ma-0 pa-0" align="center" justify="center">
          <!-- <v-col cols="12">
            <v-btn
              color="secondary"
              class="docma-text-navigation-logout"
              block
              @click="nav_bar_mini=true">
              Close Nav Bar
            </v-btn>
          </v-col> -->
          <v-col cols="4">
            <v-img
              :src="require('./assets/QuickView_Icon.svg')"
              contain
              to="/#/"
            />
          </v-col>
          <v-col cols="8" align="center" justify="center">
            <v-btn color="secondary"
              class="docma-text-navigation-logout"
              block
              outlined
              @click="logout">
              Logout
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <v-main :class="($auth.isAuthenticated && !$route.meta.hideNavbar) ? (nav_bar_mini ? 'main-page-mini' :'main-page') : 'login-page'">
      <router-view :start_date="system_start_date" :end_date="system_end_date" :system_min_start_date="system_min_start_date" :system_max_end_date="system_max_end_date" v-on:system_date_update="update_system_dates" />
    </v-main>
  </v-app>
</template>

<script>
// import BasePage from './components/BasePage';
import org_api_caller from "@/javascript/user_org_retrieval";
import api_caller from "@/javascript/chart_config_retrieval";
import {
  ALERT_GENERIC_MESSAGE,
  ALERT_TYPE_SUCCESS,
  ALERT_TYPE_ERROR,
  SUCCESS_USER_PREFERENCES_MESSAGE,
} from "@/constants/constants.js";
import { ref } from "vue";
// import { format } from "core-js/core/date";

//import Export from "./components/Sections/Export.vue";

const GRAPH_PREFERENCE_OPTIONS = ref([
  { divider: true },
  { header: "Ticketing" },
  { divider: true },
  { label: "Tickets By Priority", value: "TicketsByPriority" },
  { label: "Tickets By Status", value: "TicketsByStatus" },
  { divider: true },
  { header: "Endpoint Protection" },
  { divider: true },
  { label: "Threats By Type", value: "ThreatsByType" },
  { label: "Threats By Detection Engine", value: "ThreatsByDetectionEngine" },
  { label: "Threats By Status", value: "ThreatsByStatus" },
  { label: "Threats By Analyst Verdict", value: "ThreatsByAnalystVerdict" },
  { divider: true },
  { header: "Device Monitoring" },
  { divider: true },
  { label: "Antivirus Status", value: "AntivirusStatus" },
  { label: "Offline Devices By Type", value: "OfflineDevicesByType" },
  { divider: true },
  { header: "Email Monitoring" },
  { divider: true },
  { label: "Email Activity In Past Week", value: "EmailActivityInPastWeek" },
  { label: "Email Traffic", value: "EmailTraffic" },
  { label: "Rejections By Type", value: "RejectionsByType" },
]);

const DATA_POINT_PREFERENCE_OPTIONS = ref([
  { divider: true },
  { header: "Ticketing" },
  { divider: true },
  { label: "Open Tickets", value: "OpenTickets" },
  { label: "High Priority Tickets", value: "HighPriorityTickets" },
  { divider: true },
  { header: "Endpoint Protection" },
  { divider: true },
  { label: "Total Threats", value: "TotalThreats" },
  { label: "Unresolved Threats", value: "UnresolvedThreats" },
  { divider: true },
  { header: "Device Monitoring" },
  { divider: true },
  { label: "Offline Devices", value: "OfflineDevices" },
  { label: "Total Devices", value: "TotalDevices" },
  { divider: true },
  { header: "Email Monitoring" },
  { divider: true },
  { label: "Bounced Emails", value: "BouncedEmails" },
  { label: "Rejected Emails", value: "RejectedEmails" },
  { label: "Held Emails", value: "HeldEmails" },
  { label: "Total Emails", value: "TotalEmails" },
]);

export default {
  name: "App",

  components: {
    // BasePage,
    // Export
  },
  mounted() {
    document.title = "CyberProtection. Simplified.";
    if (this.$auth.isAuthenticated) {
      this.showTestDataDialog = true; // Show dialog on login if authenticated
    }
    // Get the current date and time in UTC
    const now = new Date();

    // Convert to EST (UTC-5, accounting for daylight saving time if applicable)
    const estOffset = 300; // EST is UTC-5 hours, or 300 minutes behind UTC
    const offsetMinutes = now.getTimezoneOffset() + estOffset;

    const estTime = new Date(now.getTime() + offsetMinutes * 60 * 1000);

    // Format the date as "YYYY-MM-DD"
    // const year = estTime.getFullYear();
    // const month = String(estTime.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    // const day = String(estTime.getDate()).padStart(2, '0');
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    this.system_max_end_date = formatDate(estTime);
    this.system_end_date = formatDate(estTime);

    const oneWeekPriorTime = new Date(estTime.getTime() - 7 * 24 * 60 * 60 * 1000);
    this.system_start_date = formatDate(oneWeekPriorTime);

  },

  data: () => ({
    user_drawer: false,
    user_orgs: {},
    data_point_options: DATA_POINT_PREFERENCE_OPTIONS,
    graph_options: GRAPH_PREFERENCE_OPTIONS,

    alertMsg: null,
    alertType: null,
    showAlert: false,

    panel: false,

    preference_data_point_1: null,
    preference_data_point_2: null,
    preference_data_point_3: null,
    preference_data_point_4: null,

    preference_graph_1: null,
    preference_graph_2: null,
    preference_graph_3: null,
    preference_graph_4: null,

    build_export: false,

    showTestDataDialog: false,
    brands_pulled: false,

    system_min_start_date: '2024-01-01',
    system_max_end_date: '2025-01-22',

    system_start_date: '2024-12-01',
    system_end_date: '2024-12-15',

    nav_bar_mini: false
  }),

  updated() {
    if (this.$auth.isAuthenticated && !this.brands_pulled) {
      this.showTestDataDialog = true;
      this.get_my_orgs();
      this.get_user_preferences();
    }
  },

  methods: {
    toggle_nav_bar: function(){
      console.log('Toggle Nav Bar')
      console.log(this.nav_bar_mini)
      this.nav_bar_mini = !this.nav_bar_mini;
      console.log(this.nav_bar_mini)
    },
    update_system_dates: function(date_range){
      console.log('System Date Update')
      console.log(date_range)
      this.system_start_date = date_range['start'];
      this.system_end_date = date_range['end'];
    },
    logout: function () {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    getUserInitial: function () {
      let initial = "";
      let name = this.$auth.user.name;
      if (name) {
        let splitted = name.split(" ");
        initial = splitted[0].substring(0, 1).toUpperCase();

        if (splitted.length > 1) {
          initial += splitted[splitted.length - 1].substring(0, 1).toUpperCase();
        }
      }
      return initial;
    },
    get_my_orgs: async function () {
      let token = await this.$auth.getTokenSilently();
      let userOrgs = await org_api_caller
        .get_user_orgs(token, this.$auth.user.sub)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (userOrgs) {
        this.user_orgs = userOrgs;
      }
    },
    switchOrg: function () {
      this.$auth.loginWithRedirect();
    },
    triggerAlert: function (type, message) {
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    },
    get_user_preferences: async function () {
      let token = await this.$auth.getTokenSilently();
      let userPreferences = await api_caller
        .get_homepage_user_preferences(token)
        .then((resp) => {
          if (!resp["success"]) {
            this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
          }
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (userPreferences) {
        let data_pref = userPreferences["data_points_stat"];
        let graph_pref = userPreferences["graphs_stat"];

        this.preference_data_point_1 = data_pref[0];
        this.preference_data_point_2 = data_pref[1];
        this.preference_data_point_3 = data_pref[2];
        this.preference_data_point_4 = data_pref[3];

        this.preference_graph_1 = graph_pref[0];
        this.preference_graph_2 = graph_pref[1];
        this.preference_graph_3 = graph_pref[2];
        this.preference_graph_4 = graph_pref[3];
        this.brands_pulled = true;
      }
    },
    update_user_preferences: async function () {
      let payload = {
        config: {
          numeric_statistic_a: this.preference_data_point_1,
          numeric_statistic_b: this.preference_data_point_2,
          numeric_statistic_c: this.preference_data_point_3,
          numeric_statistic_d: this.preference_data_point_4,
          graph_statistic_a: this.preference_graph_1,
          graph_statistic_b: this.preference_graph_2,
          graph_statistic_c: this.preference_graph_3,
          graph_statistic_d: this.preference_graph_4,
        },
      };

      let token = await this.$auth.getTokenSilently();
      let userPreferences = await api_caller
        .update_homepage_user_preferences(token, payload)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (userPreferences && userPreferences["rows_updated"] > 0) {
        this.triggerAlert(ALERT_TYPE_SUCCESS, SUCCESS_USER_PREFERENCES_MESSAGE);
        window.location.reload();
      } else {
        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },
    export_built: function () {
      this.build_export = false;
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Gothic";
  src: url("./assets/fonts/GOTHIC_0.TTF");
}
@font-face {
  font-family: "Gothic Bold";
  src: url("./assets/fonts/GOTHICB_0.TTF");
}
.docma-tool-navigation-bar {
  font-family: "Gothic Bold";
  font-size: 0.9em;
  color: black;
  text-transform: none !important;
}
.docma-text-navigation-bar-text {
  font-family: "Gothic";
  font-size: 0.9em;
  color: black;
}
.docma-text-navigation-drawer-text {
  font-family: "Gothic";
  font-size: 1.1em;
  color: black;
}
.docma-text-navigation-bar-btn {
  height: 30px !important;
}
.docma-text-navigation-logout {
  font-family: "Gothic Bold";
  font-size: 0.9em;
  color: white;
}
.docma-text-navigation {
  font-family: "Gothic";
  font-size: 0.9em !important;
  color: black;
}

.docma-text-navigation-user {
  font-family: "Gothic Bold" !important;
  font-weight: 200 !important;
  font-size: 1.1em !important;
}

.organization-title {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 0.7em !important;
  color: black;
  display: flex;
  max-width: 200px;
  align: left;

  .organization-title-content {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    margin: auto;
  }
}

.home-page-settings-action {
  font-family: "Gothic" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
  width: 196px;
}
.home-page-settings-title {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
}

.callout-headline {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 1.1em !important;
}
.callout-text {
  font-family: "Gothic" !important;
  font-weight: 400 !important;
  font-size: 1em !important;
}

.user_preferences_title {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 0.85em !important;
  color: black;
  display: flex;
  margin: 0 12px 0 8px;

  .user-preferences-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    margin: auto;
  }
}

.organization-content {
  font-size: 1em !important;
  padding-bottom: 12px;
}

.v-alert {
  position: fixed;
  left: 50%;
  /* bottom: 50px; */
  width: 100%;
  transform: translate(-50%, 0%);
  z-index: 2;
  /* margin: 0 0; */
}

.main-page {
  margin-left: 256px;
  width: calc(100vw - 256px);
  max-height: 100vh;
  overflow-y: scroll;
}
.main-page-mini{
  margin-left: 56px; 
  width: calc(100vw - 56px); 
  max-height: 100vh; 
  overflow-y: scroll;
}
.login-page{
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}
.docma-chevron-open-close-full{
  position: absolute;
  left: 238px;
  top: 175px;
  z-index: 100;
}
.docma-chevron-open-close-short{
  position: absolute;
  left: 46px;
  top: 175px;
  z-index: 100;
}
</style>
