// alert types
export const ALERT_TYPE_WARNING = 'warning';
export const ALERT_TYPE_INFO = 'info';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_ERROR = 'error'

// alert messages
export const ALERT_GENERIC_MESSAGE = 'An error had occurred. Please contact Quick Protect support.'
export const SUCCESS_USER_PREFERENCES_MESSAGE = 'Successfully updated user preferences.'

export const PENN_TEST_STATUS_OPTIONS = [
    {status_value: 1, status_text:'Closed'},
    {status_value: 2, status_text:'Open'},
    {status_value: 3, status_text:'Undefined'}
];

export const PENN_TEST_SEVERITY_OPTIONS  = [
    {severity_value: 'C', severity_text :'Critical'},
    {severity_value: 'H', severity_text: 'High'},
    {severity_value: 'M', severity_text: 'Medium'},
    {severity_value: 'L', severity_text: 'Low'},
    {severity_value: 'I', severity_text: 'Information'}
];