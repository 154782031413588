<template>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0" cols="auto">
            <v-btn icon v-if="link">
                <v-icon color="secondary" @click="open_further_details">mdi-magnify-expand</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" class="chart_style">
            <v-chart :id="chart_id" class="chart" :option="option" autoresize></v-chart>
        </v-col>
    </v-row>
</template>

<script>
    import VChart, { THEME_KEY } from 'vue-echarts';
    import "echarts";
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent,
    } from 'echarts/components';
    import {COLOR_PALETTE} from "./chart_constants.js"

    use([
        CanvasRenderer,
        TitleComponent,
        TooltipComponent,
        LegendComponent,
    ]);
    export default {
        name: 'PieChart',
        props: [
            'statistic_title_name',
            'value',
            'link',
            'chart_id'
        ],
        components: {
            VChart
        },
        provide: {
            [THEME_KEY]: 'light',
        },
        setup(props) {

            let dataset = props.value
            let data = [];
            let data_titles = [];
            if(dataset != null){
                for(let idx = 1; idx < dataset.length; idx ++){
                    data.push({
                        name: dataset[idx][0],
                        value: dataset[idx][1]
                    })
                    data_titles.push(dataset[idx][0]);
                }
            }

            const option = {
                title: {
                    text: '',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    orient: 'vertical',
                    left: '75%',
                    data: data,
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: [ '50%','95%'],
                        center: ['40%', '50%'],
                        color: COLOR_PALETTE,
                        data: data,
                        label: {
                            show: false,
                            fontSize: 14,
                            position: 'left',
                        },
                        labelLine: {
                            show: false
                        },
                    },
                ],
            }
            return {option}
        },
        methods: {
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chart_style{
        height: 200px;
        font-family: "Gothic" !important;
    }
</style>