<template>
    <v-row class="ma-0 pa-0" style="width: 175px" v-if="selected" align="center" justify="center">
        <v-col class="pb-1" cols="12" align="center">
            <span class="statistic_value_text_green" @click="selected=false; unselect_filter()">
                {{ (value == null ? statistic_value : value)}}
            </span>
        </v-col>
        <v-col cols="12" class="pt-1" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" style="width: 175px" v-else>
        <v-col class="pb-1" cols="12" align="center">
            <span class="statistic_value_text" @click="selected=true; select_filter()">
                {{ (value == null ? statistic_value : value)}}
            </span>
        </v-col>
        <v-col cols="12" class="pt-1" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'StatisticTableStat',
        props: [
            'statistic_title_name',
            'value',
            'change',
            'emphasized',
            'filter'
        ],
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            percentage: true,
            selected: false
        }),
        watch: {
            filter: function(){
                this.selected = this.filter;
            }
        },
        mounted(){
            this.selected = this.filter;
        },
        methods: {
            select_filter (){
                this.$emit('filter_value', this.statistic_title_name);
            },
            unselect_filter(){
                this.$emit('unfilter_value', this.statistic_title_name);
            }
        }
    }
</script>

<style scoped>
    .statistic_value_text{
        font-family: "Gothic Bold";
        font-size: 20pt;
    }
    .statistic_value_text_white{
        font-family: "Gothic Bold";
        font-size: 30pt;
        color: #FFFFFF;
    }
    .statistic_value_text_green{
        font-family: "Gothic Bold";
        font-size: 20pt;
        color: #1230EF;
    }
    .statistic_title{
        font-family: "Gothic";
        font-size: 10pt;
    }
    .statistic_title_white{
        font-family: "Gothic";
        font-size: 10pt;
        color: #FFFFFF;
        display: block;
        height: 50px;
    }
    .statistic_box{
        background-color: #66B85A;
        border-radius: 50%;
        min-height: 200px;
        min-width: 200px;
        max-height: 200px;
        max-width: 200px;
    }
</style>