<template>
    <v-container fluid class="px-8">
        <v-row class="ma-0 pa-0">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">Cyber Insurance Questionaire</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" class="ma-0 pa-0" align="center">
                <v-row class="ma-0 pa-0" justify="center">
                    <v-col cols="auto" align="right" class="ma-0 pa-0 mx-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <v-icon class="tier_icon" :color="current_qp_package == 'bronze' ? '#CD7F32' : 'grey'" >mdi-shield</v-icon>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <span class="tier_title">Bronze</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" align="right" class="ma-0 pa-0 mx-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <v-icon class="tier_icon" :color="current_qp_package == 'silver' ? '#C0C0C0' : 'grey'">mdi-shield-key</v-icon>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <span class="tier_title">Silver</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" align="right" class="ma-0 pa-0 mx-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <v-icon class="tier_icon" :color="current_qp_package == 'gold' ? '#D4AF37' : '#grey'">mdi-shield-crown</v-icon>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0" align="center">
                                <span class="tier_title">Gold</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="my-0 py-0">
                        <v-row>
                            <v-col cols="6" align="center">
                                <span class="question_title">No</span>
                            </v-col>
                            <v-col cols="6" align="center">
                                <span class="question_title">Yes</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="question_list.length == 0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pb-1"></v-col>
                    <v-col cols="12" class="ma-0 pa-0 py-3" v-for="i in loader" :key="i" style="height: 49px; overflow-y: hidden;">
                        <v-skeleton-loader type="image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" v-if="question_list.length > 0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pb-1"></v-col>
                    <v-col cols="12" v-for="question in question_list" :key="question.id" class="ma-0 py-0">
                        <QuestionaireEntry :question='question.text' :checked='question.checked' v-on:update_score="update_score"></QuestionaireEntry>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="12">
                <v-row class="ma-0 pa-0" justify="space-around">
                    <v-col cols="auto" align="center" class="score">
                        <span class="question_score">{{ overall_score }} / 10</span>
                    </v-col>
                </v-row>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
    import QuestionaireEntry from '../StatisticComponents/QuestionaireEntry.vue';
    import questionaire_input from "@/input_data/questionaire_input"
    import questionaire_answers_gold from "@/input_data/questionaire_answers_gold";
    import questionaire_answers_silver from "@/input_data/questionaire_answers_silver";
    import questionaire_answers_bronze from "@/input_data/questionaire_answers_bronze";
    import api_caller from '@/javascript/questionnaire_retrieval';
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";
    export default {
        name: 'QuestionaireList',
        props: [
        ],
        components: {
            QuestionaireEntry
        },
        data: () => ({
            loader: [1,2,3,4,5,6,7,8,9,10],
            question_list: [],
            current_qp_package: null,
            overall_score: 0
        }),
        mounted() {
            this.get_questionnaire_score();
        },
        watch: {
            current_qp_package: function(){
                let questionaire_answer = null;
                if(this.current_qp_package === 'bronze'){
                    questionaire_answer = questionaire_answers_bronze;
                }
                else if(this.current_qp_package === 'silver'){
                    questionaire_answer = questionaire_answers_silver;
                }
                else{
                    questionaire_answer = questionaire_answers_gold;
                }
                this.question_list = [];
                let score = 0
                let temp_list = [...questionaire_input["questionaire_list"]]
                temp_list.forEach((question, idx) => {
                    question['checked'] = questionaire_answer["questionaire_list"][idx]['checked']
                    if(question['checked']) {
                        score += 1
                    }
                });
                this.overall_score = score;
                this.question_list = temp_list;
            }
        }, 
        methods: {
            update_score(type) {
                if(type == 'incr') {
                    this.overall_score +=1
                } else if (type == 'desc') {
                    this.overall_score -=1
                }
            },
            get_questionnaire_score: async function () {
                const token = await this.$auth.getTokenSilently();
                let result = await api_caller
                    .get_questionnaire_score(token)
                    .then((resp) => {
                        console.log("resp")
                        console.log(resp)
                        if (resp["success"]) {
                            return resp["data"];
                        } else {
                            return false;
                        }
                        })
                        .catch((e) => {
                        console.log(e);
                        return false;
                    });

                if (result) {
                    this.current_qp_package = result['questionnaire_score']['score']
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
                },
                triggerAlert: function (type, message) {
                    this.alertType = type ? type : "info";
                    this.alertMsg = message;
                    this.showAlert = true;
                }
        }
    }
</script>

<style>
    .page-title {
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .question_score{
        font-family: "Gothic Bold";
        font-size: 20pt;
    }
    .question_title{
        font-family: "Gothic Bold";
        font-size: 12pt;
    }
    .tier_title{
        font-family: "Gothic Bold";
        font-size: 10pt;
    }
    .tier_icon {
        font-size: 80px !important;
    }
    .theme--light.v-icon.v-icon.v-icon--disabled {
        color: #1230EF !important
    }
</style>