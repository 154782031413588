<template>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0" cols="auto">
            <v-btn icon v-if="link">
                <v-icon color="secondary" @click="open_further_details">mdi-magnify-expand</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
            <v-row class="ma-0 pa-0" style="width: 100%; height: 100%;">
                <v-col cols="12" class="pl-0 py-1" v-for="(data_point, key) in value" :key="data_point.key">
                    <span class="chart_text">{{key + 1}}. {{data_point['stat_title'] }} ({{data_point['stat_value'] }})</span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>

    
    export default {
        name: 'CatList',
        props: [
            'statistic_title_name',
            'value',
            'link',
            'chart_id'
        ],
        components: {
        },
        provide: {
        },
        mounted(){
            console.log(this.value)
        },
        setup(props) {

            let dataset = props.value;

            let data_category = []
            let data_value = [];
            if(dataset != null){
                for(let idx = 1; idx < dataset.length; idx ++){
                    data_category.push(dataset[idx][0]);
                    data_value.push(dataset[idx][1]);
                }
            }

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'value'
                    }
                ],
                yAxis: [
                    {
                        type: 'category',
                        data: data_category,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                series: [
                    {
                    type: 'bar',
                    barWidth: '40%',
                    data: data_value,
                    },
                ],
            }
            return {option}
        },
        methods: {
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chart_text{
        font-family: "Gothic" !important;
        font-size: 8pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chart_style{
        height: 25vh;
        font-family: "Gothic" !important;
    }
</style>