<!-- <template>
    <v-row class="ma-0 pa-1 statistic_box" v-if="emphasized" align="center" justify="center">
        <v-col class="pt-1 pb-1" cols="12" align="center" >
            <span class="statistic_value_text_white">
                {{ ((value == null ? statistic_value : value) + (percentage ? "%": "")) }}
                <v-icon v-if="change > 0" color="primary">mdi-trending-up</v-icon>
                <v-icon v-else-if="change == 0" color="black">mdi-minus</v-icon>
                <v-icon v-else-if="change < 0" color="primary">mdi-trending-down</v-icon>
            </span>
        </v-col>
        <v-col class="pt-1 pb-12" cols="12" align="center">
            <span class="statistic_title_white">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
    <v-row class="ma-0 pa-0 statistic_box_a" v-else-if="odd_box">
        <v-col class="pb-1" cols="12" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-col class="pa-0 pl-4 pb-3" cols="auto" align="left">
            <span class="statistic_value_text">
                {{ (value == null ? statistic_value : value)}}
            </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0 pr-3 pb-3" cols="auto" align="left" justify="middle">
            <span class="statistic_value_change">
                {{ (change == null ? '' : change)}}
                <v-icon v-if="change > 0" color="green">mdi-trending-up</v-icon>
                <v-icon v-else-if="change == 0" color="black">mdi-minus</v-icon>
                <v-icon v-else-if="change < 0" color="red">mdi-trending-down</v-icon>
            </span>
        </v-col>
    </v-row>
    <v-row class="ma-0 pa-0 statistic_box_b" v-else>
        <v-col class="pb-1" cols="12" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-col class="pa-0 pb-3" cols="12" align="center">
            <span class="statistic_value_text">
                {{ (value == null ? statistic_value : value)}}
                <v-icon v-if="change > 0" color="green">mdi-trending-up</v-icon>
                <v-icon v-else-if="change == 0" color="black">mdi-minus</v-icon>
                <v-icon v-else-if="change < 0" color="red">mdi-trending-down</v-icon>
            </span>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'MainPageStat',
        props: [
            'statistic_title_name',
            'value',
            'change',
            'emphasized',
            'odd_box'
        ],
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            percentage: true
        })
    }
</script>

<style scoped>
    .statistic_value_text{
        font-family: "Gothic Bold";
        font-size: 20pt;
        color: #FFFFFF;
        color: #1230EF;
    }
    .statistic_value_change{
        font-family: "Gothic Bold";
        font-size: 10pt;
        color: #000000;
    }
    .statistic_value_text_white{
        font-family: "Gothic Bold";
        font-size: 30pt;
        color: #020446;
    }
    .statistic_title{
        font-family: "Gothic";
        font-size: 10pt;
        color: #020446;
        float: left;
        padding-left: 5%;
        max-width: 90%; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .statistic_title_white{
        font-family: "Gothic";
        font-size: 10pt;
        color: #020446;
        display: block;
        height: 2vh;
    }
    .statistic_arrow {
        font-size: 2vh;
    }
    .statistic_box_a{
        border-radius: 25px;
        border-color: #1230EF;
        border-width: 2px;
        border-style: solid;
    }
    .statistic_box_b{
        border-radius: 25px;
        border-color: #020446;
        border-width: 2px;
        border-style: solid;
    }
    .statistic_box{
        background-color: #BBE2FC;
        border-radius: 25px;
        
        border-color: #020446;
        border-width: 1px;


        min-height: 125px;
        min-width: 200px;
        max-height: 125px;
        max-width: 200px;
    }
</style> -->

<!-- <template>
    <v-container fluid class="statistic-box" :class="{ emphasized, odd_box }">
        <v-row align="center" justify="center">
            <v-col cols="12" class="statistic-value-container">
                <span class="statistic-value">
                    {{ displayValue }}
                    <v-icon v-if="change > 0" color="green">mdi-trending-up</v-icon>
                    <v-icon v-else-if="change === 0" color="grey">mdi-minus</v-icon>
                    <v-icon v-else color="red">mdi-trending-down</v-icon>
                </span>
            </v-col>
            <v-col cols="12" class="statistic-title">
                {{ statistic_title_name || statistic_name }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "MainPageStat",
    props: {
        statistic_title_name: String,
        value: [String, Number],
        change: Number,
        emphasized: Boolean,
        odd_box: Boolean,
        percentage: Boolean
    },
    computed: {
        displayValue() {
            const val = this.value ?? this.statistic_value;
            return this.percentage ? `${val}%` : val;
        }
    },
    data: () => ({
        statistic_value: "-",
        statistic_name: "Statistic"
    })
};
</script>

<style scoped>
.statistic-box {
    border-radius: 15px;
    padding: 15px;
    text-align: center;
    min-width: 180px;
    max-width: 280px;
    transition: all 0.3s ease-in-out;
}

/* Emphasized box styling */
.statistic-box.emphasized {
    background-color: #f0f8ff;
    border: 2px solid #1230EF;
}

/* Alternate box styles */
.statistic-box.odd_box {
    background-color: #e3ecff;
    border: 2px solid #020446;
}

/* Statistic value styles */
.statistic-value-container {
    font-size: 24px;
    font-weight: bold;
    color: #1230EF;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Statistic title styling */
.statistic-title {
    font-size: 14px;
    color: #020446;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 500;
}
</style> -->

<template>
    <v-container fluid class="statistic-box" :class="{ emphasized, odd_box }">
        <v-row align="center" justify="center">
            <v-col cols="12" class="statistic-value-container pb-1">
                <span class="statistic-value" :style="dynamicFontSize">
                    {{ displayValue }}
                </span>
                <span v-if="change != null" class="statistic-change" :class="changeColor" :style="dynamicChangeSize">
                    ({{ formattedChange }})
                    <v-icon v-if="change > 0" color="green" small>mdi-trending-up</v-icon>
                    <v-icon v-else-if="change === 0" color="grey" small>mdi-minus</v-icon>
                    <v-icon v-else-if="change < 0" color="red" small>mdi-trending-down</v-icon>
                </span>
            </v-col>
            <v-col cols="12" class="statistic-title pt-1" :style="dynamicTitleSize">
                {{ statistic_title_name || statistic_name }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "MainPageStat",
    props: {
        statistic_title_name: String,
        value: [String, Number],
        change: Number,
        emphasized: Boolean,
        odd_box: Boolean,
        percentage: Boolean
    },
    computed: {
        displayValue() {
            const val = this.value ?? this.statistic_value;
            return this.percentage ? `${val}%` : val;
        },
        formattedChange() {
            if (this.change === null || isNaN(this.change)) return "-";
            let val = this.change * 100;
            val = val.toFixed(1) ;
            val += "%"
            return  val // Formats to 1 decimal place
        },
        changeColor() {
            return this.change > 0 ? "positive" : this.change < 0 ? "negative" : "neutral";
        },
        dynamicFontSize() {
            return window.innerWidth < 1000 ? "font-size: 10px;" : "font-size: 22px;";
        },
        dynamicChangeSize() {
            return window.innerWidth < 1000 ? "font-size: 6px;" : "font-size: 12px;";
        },
        dynamicTitleSize() {
            return window.innerWidth < 1000 ? "font-size: 8px;" : "font-size: 14px;";
        }
    },
    data: () => ({
        statistic_value: "-",
        statistic_name: "Statistic"
    })
};
</script>

<style scoped>
.statistic-box {
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    max-width: 280px;
    transition: all 0.3s ease-in-out;
}

/* Emphasized box styling */
.statistic-box.emphasized {
    background-color: #f0f8ff;
    border: 2px solid #1230EF;
}

/* Alternate box styles */
.statistic-box.odd_box {
    background-color: #e3ecff;
    border: 2px solid #020446;
}

/* Statistic value styles */
.statistic-value-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: bold;
}

/* Value text */
.statistic-value {
    color: #1230EF;
}

/* Change text */
.statistic-change {
    padding-left: 5px;
    opacity: 0.8;
}

/* Color variations for change */
.positive {
    color: green;
}
.negative {
    color: red;
}
.neutral {
    color: grey;
}

/* Statistic title styling */
.statistic-title {
    color: #020446;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 500;
}
</style>
