<template>
    <v-container fluid class="px-8">
        <div v-if="showAlert">
            <v-alert
                class="v-alert"
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
                style="position: absolute"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-row class="ma-0 pa-0" justify="space-around">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">Device Monitoring</span>
                        <a class="page-link" href="https://auth.datto.com/login" target="_blank">
                            Datto RMM
                            <v-icon color="primary">mdi-open-in-new</v-icon>
                        </a>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-show="$vuetify.breakpoint.lgAndUp" md="auto" class="ma-0 pa-0 px-3">
                        <DateRange 
                            :start_date="start_date"
                            :end_date="end_date"
                            :min_start_date="min_start_date"
                            :max_end_date="max_end_date"
                            v-on:date_update="update_date_range"></DateRange>
                    </v-col> 
                    <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
                        <DateRange 
                            :start_date="start_date"
                            :end_date="end_date"
                            :min_start_date="min_start_date"
                            :max_end_date="max_end_date"
                            v-on:date_update="update_date_range"></DateRange>
                    </v-col>
                    <v-col v-show="$vuetify.breakpoint.lgAndUp" lg="auto" class="ma-0 pa-0 px-3">
                        <span class="last_updated_title">Data Last Updated</span><br>
                        <span class="last_updated_text">{{  data_info != null ? data_info : '-' }}</span>
                    </v-col>
                    <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-clock-outline</v-icon>
                            </template>
                            <TooltipDescription title="Data Last Updated." :desc_2="data_info != null ? data_info : '-'"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 py-1">
                <v-chip @click="current_page='overview'" label class="data_breakdown_chip" :outlined="current_page != 'overview'" color="primary">Overview</v-chip>
                <v-chip @click="current_page='details'" label class="data_breakdown_chip" :outlined="current_page != 'details'" color="primary">Offline Devices</v-chip>
                <v-chip @click="current_page='all_devices'" label class="data_breakdown_chip" :outlined="current_page != 'all_devices'" color="primary">All Devices</v-chip>
                <v-chip @click="current_page='all_alerts'" label class="data_breakdown_chip" :outlined="current_page != 'all_alerts'" color="primary">All Alerts</v-chip>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3">
                <MainPageStat statistic_title_name="Total Devices" :value="total_devices" :change="total_devices_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3">
                <MainPageStat statistic_title_name="Offline Devices" :value="offline_devices" :change="offline_devices_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3">
                <MainPageStat statistic_title_name="Total Down Time" :value="total_down_time" :change="total_down_time_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3">
                <MainPageStat statistic_title_name="Avg. Down Time" :value="avg_down_time" :change="avg_down_time_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3">
                <MainPageStat statistic_title_name="Patched Devices" :value="patched_devices" :change="patched_devices_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="12" class="ma-0 py-1" v-if="charts_config && current_page === 'overview'">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="ma-0 py-1" v-if="charts_config">
                        <BaseChart
                            :statistic_title_name="charts_config['charts']['AntivirusStatus']['chart_title']"
                            :value="charts_config['charts']['AntivirusStatus']['chart_value']"
                            :chart_type="charts_config['charts']['AntivirusStatus']['chart_type']"
                        >
                        </BaseChart>
                    </v-col>
                    <v-col cols="6" class="ma-0 py-1" v-if="charts_config">
                        <BaseChart
                            :statistic_title_name="charts_config['charts']['PatchStatus']['chart_title']"
                            :value="charts_config['charts']['PatchStatus']['chart_value']"
                            :chart_type="charts_config['charts']['PatchStatus']['chart_type']"
                        >
                        </BaseChart>
                    </v-col>
                    <v-col cols="5" class="ma-0 py-1" v-if="charts_config">
                        <BaseChart
                            :statistic_title_name="charts_config['charts']['DevicesByType']['chart_title']"
                            :value="charts_config['charts']['DevicesByType']['chart_value']"
                            chart_type="PieChart"
                        >
                        </BaseChart>
                    </v-col>
                    <v-col cols="7">
                        <BaseChart
                            statistic_title_name="Daily Device Overview"
                            :value="daily_overview"
                            chart_type="TimeSeriesChart"
                            chart_id="DailyOverview"
                            >
                        </BaseChart>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 py-1" v-else-if="current_page === 'overview'">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="ma-0 py-1" style="height: 262.25px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 py-1" style="height: 262.25px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="5" class="ma-0 py-1" style="height: 262.25px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="7" class="ma-0 py-1" style="height: 262.25px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" align="center" v-if="current_page == 'details'">
                <StatList statistic_title_name="Device Offline for > 24hrs" :value="offline_device_list"></StatList>
            </v-col>
            
            <v-col cols="12" v-if="current_page == 'all_devices'" class="ma-0 py-1">
                <StatisticsTable statistic_title_name="All Devices" :value="devices_lst"></StatisticsTable>
            </v-col>

            <v-col cols="12" v-if="current_page == 'all_alerts'" class="ma-0 py-1">
                <StatisticsTable statistic_title_name="All Alerts" :value="alerts_lst"></StatisticsTable>
            </v-col>

            <!-- <v-col cols="12" align="center" v-if="current_page == 'devices'">
                {{ devices_lst }}
                <StatList statistic_title_name="Device" :value="devices_lst"></StatList>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
    import StatisticsTable from '../StatisticComponents/StatisticsTable.vue';
    import MainPageStat from '../StatisticComponents/MainPageStat';   
    import StatList from '../StatisticComponents/StatisticList.vue';
    import BaseChart from '../StatisticComponents/Charts/BaseChart.vue';
    import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
    import DateRange from '../UtilityComponents/DateRange.vue';
    import api_caller from "@/javascript/chart_config_retrieval.js";
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";

    export default {
        name: 'DeviceMonitoring',
        props: [
            'start_date',
            'end_date',
            'system_min_start_date',
            'system_max_end_date'
        ],
        data: () => ({
            alertMsg: null,
            alertType: null,
            showAlert: false,
            
            current_page: 'overview',


            raw_data: null,
            offline_device_list: [],
            devices_lst: [],
            alerts_lst: [],
            total_devices: null,
            total_devices_change: null,
            offline_devices: null,
            offline_devices_change: null,
            patched_devices: null,
            patched_devices_change: null,
            total_down_time: null,
            total_down_time_change: null,
            avg_down_time: null,
            avg_down_time_change: null,
            antivirus_status: null,
            offline_device_types: null,
            charts_config: null,

            data_info: null,

            daily_overview: null,
            
            date_range: [],
            min_start_date: '2024-01-01',
            max_end_date: '2024-12-31',
            // start_date: '2024-01-01',
            // end_date: '2024-01-01'
        }),
        components: {
            MainPageStat,
            StatList,
            StatisticsTable,
            BaseChart,
            TooltipDescription,
            DateRange
        },
        mounted(){
            this.min_start_date = this.system_min_start_date;
            this.max_end_date = this.system_max_end_date;
            this.date_range = [this.start_date, this.end_date];
            this.getChartConfig();
        },
        methods: {
            update_date_range: function(date_range){
                this.date_range = date_range;
                this.$emit('system_date_update', {'start': date_range[0], 'end': date_range[1]});
                this.getChartConfig();
            },
            getChartConfig: async function () {
                this.charts_config = null;
                this.offline_device_list = [];
                this.alerts_lst = [];
                this.devices_lst = [];
                this.total_devices = null;
                this.total_devices_change = null;
                this.offline_devices = null;
                this.offline_devices_change = null;
                this.patched_devices = null;
                this.patched_devices_change = null;
                this.total_down_time = null,
                this.total_down_time_change = null,
                this.avg_down_time = null,
                this.avg_down_time_change = null,
                this.daily_overview = null;
                const token = await this.$auth.getTokenSilently();
                let charts_config = await api_caller
                    .get_device_monitoring_charts_config(token, this.date_range[0], this.date_range[1])
                    .then((resp) => {
                        if (resp["success"]) {
                            return resp["data"];
                        } else {
                            return false;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });

                if (charts_config) {
                    this.charts_config = charts_config;

                    this.offline_device_list = this.charts_config['data']['DevicesOffline'];
                    this.devices_lst = this.charts_config['data']['Devices']['content'];

                    this.total_devices = this.charts_config['data']['TotalDevices']['stat_value'];
                    this.total_devices_change = this.charts_config['data']['TotalDevices']['stat_change'];

                    this.offline_devices = this.charts_config['data']['OfflineDevices']['stat_value'];
                    this.offline_devices_change = this.charts_config['data']['OfflineDevices']['stat_change'];

                    this.patched_devices = this.charts_config['data']['PatchedDevices']['stat_value'];
                    this.patched_devices_change = this.charts_config['data']['PatchedDevices']['stat_change'];

                    this.total_down_time = this.charts_config['data']['TotalDownTime']['stat_value'];
                    this.total_down_time_change = this.charts_config['data']['TotalDownTime']['stat_change'];

                    this.avg_down_time = this.charts_config['data']['AvgDownTime']['stat_value'];
                    this.avg_down_time_change = this.charts_config['data']['AvgDownTime']['stat_change'];

                    this.antivirus_status = this.charts_config['data']['AntivirusStatus'];
                    this.offline_device_types = this.charts_config['data']['OfflineDevicesByType'];
                    this.alerts_lst = this.charts_config['data']['Alerts']['content'];

                    this.daily_overview = this.charts_config['charts']['DeviceStatusDaily']['chart_value'];

                    this.data_info = charts_config['data_info']['data_last_updated'];
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            triggerAlert: function (type, message) {
                this.alertType = type ? type : "info";
                this.alertMsg = message;
                this.showAlert = true;
            },
        }
    }
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title{
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .page-link{
        font-family: "Gothic" !important;
        font-size: 15pt !important;
        margin-left: 10px;
        /* color: #66B85A; */
        color: #020446;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }
</style>