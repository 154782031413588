<template>
    <v-container fluid class="px-8">
        <div v-if="showAlert">
            <v-alert
                class="v-alert"
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
                style="position: absolute"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-row class="ma-0 pa-0" justify="space-around">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">Endpoint Protection</span>
                        <a class="page-link" href="https://usea1-011.sentinelone.net/login" target="_blank">
                            SentinelOne
                            <v-icon color="primary">mdi-open-in-new</v-icon>
                        </a>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-show="$vuetify.breakpoint.mdAndUp" md="auto" class="ma-0 pa-0 px-3">
                        <DateRange 
                            :start_date="start_date"
                            :end_date="end_date"
                            :min_start_date="min_start_date"
                            :max_end_date="max_end_date"
                            v-on:date_update="update_date_range"></DateRange>
                    </v-col> 
                    <v-col v-show="$vuetify.breakpoint.lgAndUp" lg="auto" class="ma-0 pa-0 px-3">
                        <span class="last_updated_title">Data Last Updated</span><br>
                        <span class="last_updated_text">{{  data_info != null ? data_info : '-' }}</span>
                    </v-col>
                    <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
                        <v-row class="ma-0 pa-0" align="center" justify="center">
                            <v-col cols="auto" class="ma-0 pa-0">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-clock-outline</v-icon>
                                    </template>
                                    <TooltipDescription title="Data Last Updated." :desc_2="data_info != null ? data_info : '-'"></TooltipDescription>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 py-1">
                <v-chip @click="switch_tab('overview')" label class="data_breakdown_chip" :outlined="current_page != 'overview'" color="primary">Threat Overview</v-chip>
                <v-chip @click="switch_tab('agent_overview')" label class="data_breakdown_chip" :outlined="current_page != 'agent_overview'" color="primary">Agent Overview</v-chip>
                <v-chip @click="switch_tab('details')" label class="data_breakdown_chip" :outlined="current_page != 'details'" color="primary">Threat Details</v-chip>
                <v-chip @click="switch_tab('agent_details')" label class="data_breakdown_chip" :outlined="current_page != 'agent_details'" color="primary">Agent Details</v-chip>
            </v-col>

            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Total Threats" :value="total_threats" :change="total_threats_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Unresolved Threats" :value="unresolved_threats" :change="unresolved_threats_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Threats Blocked" :value="auto_resolved_threats" :change="auto_resolved_threats_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Avg. Time to Identify" :value="avg_time_to_identify" :change="avg_time_to_identify_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Avg. Time to Resolve" :value="avg_time_to_resolve" :change="avg_time_to_resolve_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="12" v-if="charts_config && current_page === 'overview'" class="ma-0 py-1">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['ThreatsByStatus']['chart_title']"
                            :value="charts_config['charts']['ThreatsByStatus']['chart_value']"
                            :chart_type="charts_config['charts']['ThreatsByStatus']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['ThreatsByType']['chart_title']"
                            :value="charts_config['charts']['ThreatsByType']['chart_value']"
                            :chart_type="charts_config['charts']['ThreatsByType']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['ThreatsByDetectionEngine']['chart_title']"
                            :value="charts_config['charts']['ThreatsByDetectionEngine']['chart_value']"
                            :chart_type="charts_config['charts']['ThreatsByDetectionEngine']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['ThreatsByAnalystVerdict']['chart_title']"
                            :value="charts_config['charts']['ThreatsByAnalystVerdict']['chart_value']"
                            :chart_type="charts_config['charts']['ThreatsByAnalystVerdict']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['ThreatsByMitigation']['chart_title']"
                            :value="charts_config['charts']['ThreatsByMitigation']['chart_value']"
                            :chart_type="charts_config['charts']['ThreatsByMitigation']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['ThreatsByAutoResolved']['chart_title']"
                            :value="charts_config['charts']['ThreatsByAutoResolved']['chart_value']"
                            :chart_type="charts_config['charts']['ThreatsByAutoResolved']['chart_type']"
                        ></BaseChart>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 py-1" v-else-if="current_page === 'overview'">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="ma-0 pa-3" style="height: 264px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-3" style="height: 264px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-3" style="height: 264px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-3" style="height: 264px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-3" style="height: 264px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-3" style="height: 264px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="2" v-if="current_page === 'agent_overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Total Agents" :value="total_agents" :change="total_agents_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'agent_overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="New Agents" :value="new_agents" :change="new_agents_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'agent_overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Scanned Agents" :value="scanned_agents" :change="scanned_agents_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'agent_overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Decomminissed Agents" :value="decommissioned_agents" :change="decommissioned_agents_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="2" v-if="current_page === 'agent_overview'" class="ma-0 py-3" >
                <MainPageStat statistic_title_name="Uninstalled Agents" :value="uninstalled_agents" :change="uninstalled_agents_change" :odd_box="true"></MainPageStat>
            </v-col>
            <v-col cols="12" v-if="charts_config && current_page === 'agent_overview'" class="ma-0 py-1">
                <v-row class="ma-0 pa-0">
                    <!-- <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['AgentsByMachineType']['chart_title']"
                            :value="charts_config['charts']['AgentsByMachineType']['chart_value']"
                            :chart_type="charts_config['charts']['AgentsByMachineType']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['AgentsByOSType']['chart_title']"
                            :value="charts_config['charts']['AgentsByOSType']['chart_value']"
                            :chart_type="charts_config['charts']['AgentsByOSType']['chart_type']"
                        ></BaseChart>
                    </v-col> -->
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['AgentsByType']['chart_title']"
                            :value="charts_config['charts']['AgentsByType']['chart_value']"
                            :chart_type="charts_config['charts']['AgentsByType']['chart_type']"
                            :tall="true"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="6" align="center" class="py-1">
                        <BaseChart 
                            :statistic_title_name="charts_config['charts']['AgentsByOSMachineType']['chart_title']"
                            :value="charts_config['charts']['AgentsByOSMachineType']['chart_value']"
                            :chart_type="charts_config['charts']['AgentsByOSMachineType']['chart_type']"
                            :tall="true"
                        ></BaseChart>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 py-1" v-else-if="current_page === 'agent_overview'">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="ma-0 pa-3" style="height: 490px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image, image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-3" style="height: 490px; overflow-y: hidden;">
                        <v-skeleton-loader type="image, image, image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" v-if="current_page === 'details'" class="ma-0 py-1" >
                <StatisticsTable statistic_title_name="Recent Security Events" :value="recent_events"></StatisticsTable>
            </v-col>
            <v-col cols="12" v-if="current_page === 'agent_details'" class="ma-0 py-1" >
                <StatisticsTable statistic_title_name="Agents" :value="agent_details"></StatisticsTable>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BaseChart from '../StatisticComponents/Charts/BaseChart.vue';
    import DateRange from '../UtilityComponents/DateRange.vue';
    import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
    import StatisticsTable from '../StatisticComponents/StatisticsTable.vue';
    import MainPageStat from '../StatisticComponents/MainPageStat.vue';
    import api_caller from "@/javascript/chart_config_retrieval.js";
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";

    export default {
        name: 'EndpointProtection',
        props: [
            'start_date',
            'end_date',
            'system_min_start_date',
            'system_max_end_date'
        ],
        data: () => ({
            alertMsg: null,
            alertType: null,
            showAlert: false,

            current_page: 'overview',

            charts_config: null,
            total_threats: null,
            total_threats_change: null,
            unresolved_threats: null,
            unresolved_threats_change: null,
            auto_resolved_threats: null,
            auto_resolved_threats_change: null,
            avg_time_to_identify: null,
            avg_time_to_identify_change: null,
            avg_time_to_resolve: null,
            avg_time_to_resolve_change: null,
            
            total_agents: null,
            total_agents_change: null,
            new_agents: null,
            new_agents_change: null,
            scanned_agents: null,
            scanned_agents_change: null,
            decommissioned_agents: null,
            decommissioned_agents_change: null,
            uninstalled_agents: null,
            uninstalled_agents_change: null,

            recent_events: [],
            agent_details: [],

            data_info: null,
            
            date_range: [],
            min_start_date: '2024-01-01',
            max_end_date: '2024-12-31',
            // start_date: '2024-01-01',
            // end_date: '2024-01-01'
        }),
        components: {
            TooltipDescription,
            StatisticsTable,
            BaseChart,
            MainPageStat,
            DateRange
        },
        mounted(){
            this.date_range = [this.start_date, this.end_date];
            this.min_start_date = this.system_min_start_date;
            this.max_end_date = this.system_max_end_date;
            this.getChartConfig();
        },
        methods: {
            switch_tab: async function(new_tab){
                let cc = {...this.charts_config};
                this.charts_config = null;
                this.current_page = new_tab;
                setTimeout(() => this.charts_config = cc, 500);
            },
            update_date_range: function(date_range){
                this.date_range = date_range;
                this.$emit('system_date_update', {'start': date_range[0], 'end': date_range[1]});
                this.getChartConfig();
            },
            getChartConfig: async function () {
                this.charts_config = null;

                this.total_threats = null;
                this.total_threats_change = null;
                this.unresolved_threats = null;
                this.unresolved_threats_change = null;
                this.auto_resolved_threats = null;
                this.auto_resolved_threats_change = null;
                this.avg_time_to_identify = null;
                this.avg_time_to_identify_change = null;
                this.avg_time_to_resolve = null;
                this.avg_time_to_resolve_change = null;

                this.total_agents = null;
                this.total_agents_change = null;
                this.new_agents = null;
                this.new_agents_change = null;
                this.scanned_agents = null;
                this.scanned_agents_change = null;
                this.uninstalled_agents = null;
                this.uninstalled_agents_change = null;
                this.decommissioned_agents = null;
                this.decommissioned_agents_change = null;

                this.recent_events = [];
                const token = await this.$auth.getTokenSilently();
                let charts_config = await api_caller
                    .get_endpoint_protection_charts_config(token, this.date_range[0], this.date_range[1])
                    .then((resp) => {
                        if (resp["success"]) {
                            return resp["data"];
                        } else {
                            return false;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });

                if (charts_config) {
                    this.charts_config = charts_config;

                    this.total_threats = charts_config['data']['TotalThreats']['stat_value'];
                    this.total_threats_change = charts_config['data']['TotalThreats']['stat_change'];

                    this.unresolved_threats = charts_config['data']['UnresolvedThreats']['stat_value'];
                    this.unresolved_threats_change = charts_config['data']['UnresolvedThreats']['stat_change'];

                    this.auto_resolved_threats = charts_config['data']['AutoResolvedThreats']['stat_value'];
                    this.auto_resolved_threats_change = charts_config['data']['AutoResolvedThreats']['stat_change'];

                    this.avg_time_to_identify = charts_config['data']['AvgIdentificationTime']['stat_value'];
                    this.avg_time_to_identify_change = charts_config['data']['AvgIdentificationTime']['stat_change'];

                    this.avg_time_to_resolve = charts_config['data']['AvgResolutionTime']['stat_value'];
                    this.avg_time_to_resolve_change = charts_config['data']['AvgResolutionTime']['stat_change'];

                    this.total_agents = charts_config['data']['TotalAgents']['stat_value'];
                    this.total_agents_change = charts_config['data']['TotalAgents']['stat_change'];

                    this.new_agents =  charts_config['data']['NewAgents']['stat_value'];
                    this.new_agents_change =  charts_config['data']['NewAgents']['stat_change'];

                    this.scanned_agents = charts_config['data']['ScannedAgents']['stat_value'];
                    this.scanned_agents_change = charts_config['data']['ScannedAgents']['stat_change'];

                    this.uninstalled_agents = charts_config['data']['UninstalledAgents']['stat_value'];
                    this.uninstalled_agents_change = charts_config['data']['UninstalledAgents']['stat_change'];

                    this.decommissioned_agents = charts_config['data']['DecommissionedAgents']['stat_value'];
                    this.decommissioned_agents_change = charts_config['data']['DecommissionedAgents']['stat_change'];

                    this.data_info = charts_config['data_info']['data_last_updated'];

                    this.recent_events = this.charts_config['tables']['RecentEvents']['content'];

                    this.agent_details = this.charts_config['tables']['Agents']['content'];
                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },

            triggerAlert: function (type, message) {
                this.alertType = type ? type : "info";
                this.alertMsg = message;
                this.showAlert = true;
            },
        }
    }
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title{
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .page-link{
        font-family: "Gothic" !important;
        font-size: 15pt !important;
        margin-left: 10px;
        color: #020446;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }
</style>