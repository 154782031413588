export const COLOR_PALETTE = [
    // "#66B85A", 
    // "#FF6F61",  
    // "#6A5ACD", 
    // "#FFA07A",  
    // "#20B2AA",  
    // "#FFD700",  
    // "#4682B4",  
    // "#FF6347",  
    // "#8A2BE2",  
    // "#FF4500"  
    "#020446", // Base color
    "#1230EF", // Base color
    "#BBE2FC", // Base color
    "#F4A261",
    "#2A9D8F",
    "#E9C46A",
    "#264653",
    "#E76F51",
    "#9C6644",
    "#6A0572",
    "#F4B400",
    "#90BE6D",
    "#EF476F",
    "#118AB2",
    "#073B4C",
    "#FFD166",
    "#3A86FF",
    "#8338EC",
    "#FF006E",
    "#FB5607"
];
