<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="12" class="py-1" v-if="chart_type=='BarChart'">
            <BarChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
                :tall="tall"
            />
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='DualTimeSerieChart'">
            <DualTimeSerieChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
            />
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='PieChart'">
            <PieChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
            ></PieChart>
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='StackedBarChart'">
            <StackedBarChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
                :tall="tall"
            />
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='TimeSeriesChart'">
            <TimeSerieChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
            />
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='TwoSidedBarChart'">
            <TwoSidedBarChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
            />
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='FunnelChart'">
            <FunnelChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
            />
        </v-col>
        <v-col cols="12" class="py-1" v-else-if="chart_type=='List'">
            <CatList
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_id="chart_id"
            />
        </v-col>
    </v-row>
</template>

<script>
    import PieChart from "./PieChart.vue";
    import FunnelChart from "./FunnelChart.vue";
    import BarChart from "./BarChart.vue";
    import DualTimeSerieChart from "./DualTimeSerieChart.vue"
    import StackedBarChart from "./StackedBarChart.vue"
    import TimeSerieChart from "./TimeSerieChart.vue"
    import TwoSidedBarChart from "./TwoSidedBarChart.vue"
    import CatList from "./CatList.vue";

    export default {
        name: 'BaseChart',
        props: [
            'statistic_title_name',
            'value',
            'link',
            'chart_type',
            'chart_id',
            'tall'
        ],
        components: {
            BarChart,
            DualTimeSerieChart,
            PieChart,
            StackedBarChart,
            TimeSerieChart,
            TwoSidedBarChart,
            FunnelChart,
            CatList
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>