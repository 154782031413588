import { render, staticRenderFns } from "./EndpointProtection.vue?vue&type=template&id=63464af2&scoped=true"
import script from "./EndpointProtection.vue?vue&type=script&lang=js"
export * from "./EndpointProtection.vue?vue&type=script&lang=js"
import style0 from "./EndpointProtection.vue?vue&type=style&index=0&id=63464af2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63464af2",
  null
  
)

export default component.exports