<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
            <v-menu
                ref="date_range_picker"
                v-model="date_range_picker"
                :close-on-content-click="false"
                :return-value.sync="date_range"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="toolbar_date_range_selector"
                        v-model="dateRangeText"
                        label="Date Range"
                        prepend-icon="mdi-calendar-range"
                        readonly
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                    ></v-text-field>
                </template>
                <v-row class="ma-0 pa-0" style="background-color: white; width: 450px;">
                    <v-col cols="4" class="ma-0 pa-3">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isThisWeek }" @click="setThisWeek">
                                    This Week
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isLastWeek }" @click="setLastWeek">
                                    Last Week
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isThisMonth }" @click="setThisMonth">
                                    This Month
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="8" class="ma-0 pa-0" style="min-width: 300px">
                        <v-date-picker
                            class="toolbar_selector"
                            v-model="date_range"
                            :min="min_start_date"
                            :max="max_end_date"
                            no-title
                            scrollable
                            range
                            color="secondary"
                        >
                            <v-row class="ma-0 pa-0">
                                <v-col cols="6">
                                    <v-btn
                                        class="toolbar_date_btn"
                                        outlined
                                        color="primary"
                                        @click="date_range_picker = false"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        class="toolbar_date_btn"
                                        outlined
                                        color="primary"
                                        @click="$refs.date_range_picker.save(date_range); commit_date_range()"
                                    >
                                        OK
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-date-picker>
                    </v-col>
                </v-row>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'DateRange',
    props: ['start_date', 'end_date', 'min_start_date', 'max_end_date'],
    data: () => ({
        date_range_picker: false,
        date_range: [],
    }),
    computed: {
        dateRangeText() {
            return this.date_range.join(' ~ ');
        },
        isThisWeek() {
            const now = new Date();
            const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            return this.compareDates([startOfWeek, endOfWeek]);
        },
        isLastWeek() {
            const now = new Date();
            const startOfLastWeek = new Date(now.setDate(now.getDate() - now.getDay() - 7));
            const endOfLastWeek = new Date(startOfLastWeek);
            endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
            return this.compareDates([startOfLastWeek, endOfLastWeek]);
        },
        isThisMonth() {
            const now = new Date();
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            return this.compareDates([startOfMonth, endOfMonth]);
        },
    },
    methods: {
        commit_date_range() {
            if(this.date_range[0] > this.date_range[1]){
                let start = this.date_range[1];
                let end = this.date_range[0];
                this.date_range = [start, end];
            }
            this.$emit('date_update', this.date_range);
        },
        compareDates(range) {
            const [start, end] = range;
            const [selectedStart, selectedEnd] = this.date_range;
            return (
                start.toISOString().substr(0, 10) === selectedStart &&
                end.toISOString().substr(0, 10) === selectedEnd
            );
        },
        setThisWeek() {
            const now = new Date();
            const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            this.date_range = [
                startOfWeek.toISOString().substr(0, 10),
                endOfWeek.toISOString().substr(0, 10),
            ];
        },
        setLastWeek() {
            const now = new Date();
            const startOfLastWeek = new Date(now.setDate(now.getDate() - now.getDay() - 7));
            const endOfLastWeek = new Date(startOfLastWeek);
            endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
            this.date_range = [
                startOfLastWeek.toISOString().substr(0, 10),
                endOfLastWeek.toISOString().substr(0, 10),
            ];
        },
        setThisMonth() {
            const now = new Date();
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            this.date_range = [
                startOfMonth.toISOString().substr(0, 10),
                endOfMonth.toISOString().substr(0, 10),
            ];
        },
    },
    watch: {
        start_date: {
            immediate: true,
            handler() {
                this.date_range = [this.start_date, this.end_date];
            },
        },
        end_date: {
            immediate: true,
            handler() {
                this.date_range = [this.start_date, this.end_date];
            },
        },
    },
};
</script>

<style scoped>
.toolbar_date_btn {
    font-family: 'Gothic';
    font-size: 0.9em;
    width: 100%;
    border-color: #020446;
    /* background-color: #020446; */
}
.v-btn--active {
    /* border-color: #000000;
    background-color: #000000; */
    border-color: #020446;
    background-color: #020446;
    color: white !important;
}
.toolbar_date_range_selector{
    font-family: 'Gothic';
    font-size: 0.9em;
    width: 200px;
}
.toolbar_selector{
    font-family: 'Gothic';
    font-size: 0.9em;
    /* width: 200px; */
}
</style>
